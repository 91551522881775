import { useState,useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function Secure() {

  const CodeField = useRef('')
  const [ErreurToken,setErreurToken] = useState(false);
  const id = useSelector(state => state.userData.id);
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const Soumettre=async () => {
    setErreurToken(false)
    const token2fa = CodeField.current.value
    try {
    const response = await axios.post(`${apiUrl}secure/${id}/${token2fa}`);
    if(response.data.status==="ok"){
      const token = response.data.token
      Cookies.set('token', token, { 
          expires: 30,
          secure: true, 
          sameSite: 'Strict' 
        })
        navigate('/home');
    }
    else {
      setErreurToken(true)
    }
  }
  catch (error){

  }
}

  return(
    <div className="container-lg align-items-center h-100">

<div class="row justify-content-center align-items-center h-100">
<div class="col-md-8 col-lg-6 col-xl-5">
    <div class="card mt-4">

        <div class="card-body p-4">
            <div class="text-center mt-2">
                <h5 class="text-primary">Authentification à deux facteurs</h5>
                <p class="text-muted">Veuillez entrer le code de votre application 2FA</p>
            </div>
            <div class="p-2 mt-4">
                    {ErreurToken ?
                            <div class="alert alert-danger  mb-4 text-center" role="alert">
                            <strong>Code incorrecte. Veuillez réessayer</strong>
                        </div>                 
                    :
                    ''                    
                }
                    <div className="mb-3">
                    <label className="form-label mb" for="code">Code 2FA</label>
                    <input type="text" className="form-control" id="code" required ref={CodeField}></input>

                    </div>

                    <div className="mb-3">

                    <button type="button" className="btn btn-success w-100" onClick={Soumettre}>Soumettre</button>

                    </div>
            </div>
        </div>
    </div>
</div>
</div>
    </div>
)
}