import { useState,useRef } from 'react';
import { useDispatch } from 'react-redux';
import { creation } from './userSlice';
import { useNavigate } from 'react-router-dom';
import { Turnstile } from '@marsidev/react-turnstile';
import axios from 'axios';
import Cookies from 'js-cookie';
export default function Login() {

    const ForceTotp = process.env.REACT_APP_TOTP;

    const telField= useRef('');
    const passwordField = useRef('');
    const [etat,setEtat] = useState('');

    const [canSubmit, setCanSubmit] = useState('');
    const refTurnstile = useRef(null);

    function EtatSubmit()
    {
        if(canSubmit==="tokenOk")
            return(
                <div className="mt-4">
                <button type="submit" className="btn btn-success w-100">Se connecter</button>
            </div>
        )
        else if(canSubmit==="loading")
            return(
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Connexion...</span>
                </button>
                </div>          
                )
            else 
            return('')
    }

    const dispatch= useDispatch();
    const navigate = useNavigate();

    const handlConnect =async (e) => {
        setEtat('')
        e.preventDefault()
        setCanSubmit('loading')
        const tel=telField.current.value.trim()
        const password=passwordField.current.value.trim()
        const token = refTurnstile.current.getResponse()

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}login`, {
                tel,
                password,
                token,
            });
            
            dispatch(creation(response.data))
             if(ForceTotp==="non"){
                navigate('/home');
                return
             }
            
            const iduser=response.data["_id"];
            const totp=response.data["totp"];
            const token2fa = Cookies.get('token');
            if(token2fa){
                const response2 = await axios.post(`${apiUrl}check/token/${iduser}/${token2fa}`);
                if(response2.data.status==="ok"){
                navigate('/home');
                }
            else {
                if(totp===""){
                    navigate('setup2fa');
                }
                else{
                navigate('secure');
            }
            }
            }
            else {
                if(totp===""){
                    navigate('setup2fa');
                }
                else{
                navigate('secure');
            }
        }
            setCanSubmit('');
            refTurnstile.current?.reset();
        } catch (error) {
            setEtat('erreur');
            setCanSubmit('');
            refTurnstile.current?.reset();
        }
    }

    return(
        <div className="container-lg align-items-center h-100">

<div class="row justify-content-center align-items-center h-100">
    <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card mt-4">

            <div class="card-body p-4">
                <div class="text-center mt-2">
                    <h5 class="text-primary">Identification</h5>
                    <p class="text-muted">Connectez vous à votre compte</p>
                </div>
                <div class="p-2 mt-4">
                    <form onSubmit={handlConnect}>
                        {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Identifiant ou mot de passe incorrecte</strong>
                            </div>                 
                        :
                        ''                    
                    }
                        <div className="mb-3">
                        <label className="form-label mb" for="tel">N° Téléphone</label>
                        <input type="text" className="form-control" id="tel" placeholder="Entrez votre N° Tel" required ref={telField}></input>
  
                        </div>

                        <div class="mb-3">
                            <div className="float-end">
                            </div>
                            <label className="form-label mb" for="password">Mot de passe</label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                            <input type="password" className="form-control pe-5" id="password" placeholder="Saisissez votre Mot de passe" required ref={passwordField}></input>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                    <Turnstile
                    options={{
                        theme: 'light',
                        // size: 'compact',
                        language: 'fr',
                    }}
                    id='turnstile-1'
                    ref={refTurnstile}
                    siteKey='0x4AAAAAAASYjlyv4R1olnPH'
                    onSuccess={() => setCanSubmit('tokenOk')}
                     />
                        </div>
                        <EtatSubmit/>
                        </form>
                </div>
            </div>
        </div>
    </div>
</div>
        </div>
    )
}