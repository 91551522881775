import {useState,useEffect} from 'react';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,getList,ajout,remove,listproduit } from "./PromosSlice";

import ModalAjouter from './ModalAjouter';


export default function Promotions() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [Chargement, setChargement] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const promoData = useSelector(state => state.promosData);
  const ajoutSuccess=promoData.ajoutOk;
  const listPromos = promoData.list;

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };

  const VerifInterval = (debut,fin) =>{
    const [date_debut] = debut.split('T');
    const [date_fin] = fin.split('T');
    const today = new Date();
    const start = new Date(date_debut);
    const end = new Date(date_fin);
    end.setHours(23, 59, 59, 999);
    if (today >= start && today <= end) {
      return("Active");
    } else {
      return("Terminée");
    }

  }

  const id = useSelector(state => state.userData.id);
  const dispatch= useDispatch();

  const GetProduits=async () => {
    try {
    const response = await axios.post(`${apiUrl}produits/${id}`);

    const produitsData = response.data.map(produit => ({
        value: produit._id,
        label: produit.designation,
      }));
      dispatch(listproduit(produitsData));
    }
 catch (error) {
    console.error('Error:', error);
}
}

  const getPromos=async () => {
    setChargement(true);
    try {
    const response = await axios.post(`${apiUrl}promos/${id}`);
    dispatch(getList(response.data));
    }
 catch (error) {
    console.error('Error:', error);    
}
setChargement(false);
}

  const Ajouter = () =>{
    dispatch(showmodal(true))
}
const HideSuccessMsg = () => {
  setTimeout(() => {
      dispatch(ajout(false));
      setRemoveSuccess(false);
  }, 5000);
}

const suppression = async (idpromo)=> {

  try {
      const response = await axios.post(`${apiUrl}delete/promo/${id}/${idpromo}`);
      if(response.data.status==="ok")
          {
              dispatch(remove(idpromo));
              setRemoveSuccess(true);
              HideSuccessMsg();
          }
  } catch (error) {
      console.log(error)
  }
}
const supprimer = (idpromo) => {

  let result = window.confirm(`Supprimer cette promotion ?`);
  if (result === true) {
      suppression(idpromo);
  }
}

function ShowList() { 
  HideSuccessMsg();

  return listPromos.map ( Promo => {
    const valid = VerifInterval(Promo.date_debut,Promo.date_fin)
    let StyleColor = "#99FBA9"
    if(valid==="Terminée"){
      StyleColor = "#FBD399"
    }

    return(
      <tr key={Promo._id}>
        <td>{Promo.produit.designation}</td>
        <td>{Promo.valeur} %</td>
        <td>{formatDateToFrench(Promo.date_debut)}</td>
        <td>{formatDateToFrench(Promo.date_fin)}</td>
        <td>{Promo.remarque}</td>
        <td style={{color:StyleColor}}>{valid}</td>
        <td><button type="button" class="btn btn-danger" onClick={() => supprimer(Promo._id)}>Supprimer</button></td>

      </tr>
    )
  })

  }

useEffect(() =>{
  getPromos();
  GetProduits();
},[]);

  return (
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Promotions</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter une promotion
    </button>
  </div>

  <div className="d-flex justify-content-start mb-3 w-50" >
        
    </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Promotion ajoutée avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Promotion suprimée avec succès</strong>
        </div>                 
            :
            ''                    
        }
        <div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
            <th scope="col">Produit</th>
                <th scope="col">Pourcentage</th>
                <th scope="col">Date Debut</th>
                <th scope="col">Date Fin</th>
                <th scope="col">Remarque</th>
                <th scope="col">Etat</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
</section>
<ModalAjouter/>
    </div>
  )
}
