import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { modifsuccess,showapercu } from "./FactureSlice";
import Catlist from '../produits/Catlist';
import Select from 'react-select';
import ModalApercu from './ModalApercu';
import Listfournisseurs from './Listfournisseurs';

export default function Editfacture() {
    const id = useSelector(state => state.userData.id);
    const Facturedata = useSelector(state => state.facturesData);
    const listClients = Facturedata.listClients;
    const currentfacture = Facturedata.currentFacture;

    const [listAvoirClient, setlistAvoirClient] = useState([]);
    const [Chargement, setChargement] = useState(false);
    const [listProduits, setListProduits] = useState([]);
    const [rechercheInput,setRchercheInput] = useState('');
    const [rechercheCat,setRchercheCat] = useState('tout');
    const [fournisseur,setFournisseur] = useState('tout');
    const [etat,setEtat] =useState('');
    const [currentClient,setcurrentClient] =useState('');
    
    const clientField=useRef('');
    const catField=useRef('');
    const rechercheField=useRef('');
    const factureField=useRef('');
    const reglementField=useRef('');
    const etatField=useRef('');
    const livraisonField=useRef('');
    const avoirField=useRef('');
    const dateField=useRef('');
    const fournisseurField = useRef('');
    const currentAvoir=useRef('');
    let listAchats=useRef([]);
    const ListPromotion = useRef([]);


    const CheckPromo = async () =>{
        try {
            const date = formatDateToIso(dateField.current.value)
        const response = await axios.post(`${apiUrl}checkpromo/${id}/${date}`);
        ListPromotion.current=response.data["promo"].flat()

    }
        
            catch (error) {
                console.error('Error:', error);
            }
    }

    const handleDateChange = (event) => {
        CheckPromo();
      };
    
    const InstCurrentAvoir = () => {
        try{
        currentfacture.map((facture) => (
            currentAvoir.current={
              "id" : facture.avoir[0].id,
              "numero" : facture.avoir[0].numero,
              "montant" : facture.avoir[0].montant,          
            }
          ))
        }
        catch (error) {
        }
    }
      const SelectClient =() =>{
      currentfacture.map((facture) => (
        setcurrentClient({
            value : facture.client[0].id,
            label : facture.client[0].designation,        
        })
      ))
    }
    const navigate = useNavigate();
    const dispatch= useDispatch();

    const apiUrl = process.env.REACT_APP_API_URL;

    const optionClients = listClients.map(client => ({
        value: client._id,
        label: client.designation,
      }));
    
    const formatDateToIso = (dateString) => {
    const formattedDate = new Date(`${dateString}T00:00:00Z`);
    formattedDate.setHours(23, 59, 59, 999);
    return formattedDate.toISOString();
    };

    const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${year}-${month}-${day}`;
      };

      const formatDateToFrench2 = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const removeZeroStock = (list) => {
        return list.current.filter(item => item.stock !== "0" && item.stock !== 0);
      };

      const calculateSum = (products) => {
        return products.reduce((total, product) => {
            if(product.stock === 0) {
                product.stock = 0
            }
            const tvaAmount = product.prix_vente * (product.tva / 100);
            return total + ((product.prix_vente + tvaAmount) * product.stock);
        
        }, 0);
    };

    const StockUpdate = (stock,event) => {
        let promo=0
        let qte = event.target.value;
        if(qte>stock){
            alert(`La valeur ne doit pas dépasser ${stock}`)
            event.target.value=0
            qte = 0
        }
        const id = event.target.id;
        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                // product.stock = qte;
                const _id=product._id;
                const designation=product.designation;
                // const stock=product.stock;
                const stock=qte;
                let prix_vente=product.prix_vente;
                const prix_achat=product.prix_achat;
                const tva=product.tva;
                const promotion = ListPromotion.current.find(promo => promo.produit.id === product._id);
                if (promotion && stock>0) {
                    const discount = promotion.valeur;
                    prix_vente = product.prix_vente - (product.prix_vente * (discount / 100));
                    promo=product.prix_vente * (discount / 100) * stock
                }
                const valeurs={_id,designation,stock,prix_vente,promo,prix_achat,tva};
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock
                    listAchats.current[existingProductIndex].stock = stock;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                return { listAchats };
            }
            return product;
            
        });
        
    };
    function ShowAvoir() {
        if(listAvoirClient.length > 0)
            return listAvoirClient.map(avoir => {

                if(avoir._id===currentAvoir.current.id)
                return(<option value={avoir._id} selected> N° : {avoir.numero} -  {avoir.montant}</option>)
                else
                return(<option value={avoir._id}> N° : {avoir.numero} -  {avoir.montant}</option>)
            })
    }

      function ShowList() {
        if(listProduits.length > 0){
            let listFiltre=listProduits

            if (fournisseur!=="tout")
                {
                    listFiltre=listFiltre.filter(produit => produit.fournisseur["0"].id===fournisseur)
                }
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(produit => produit.designation.includes(rechercheInput))
            }
            if (rechercheCat !=="tout"){
                listFiltre = listFiltre.filter(produit => 
                    produit.categorie.some(categorie => 
                      categorie.id===rechercheCat) 
                    )
                ;               
            }
        return listFiltre.map(Produit => {
            const currentQte = listAchats.current.find(item => item._id === Produit._id);
            let stock="0";
            if(currentQte){
                stock=currentQte.stock;
            }

            return(
                <tr key={Produit._id}>
                <td>{Produit.designation}</td>
                <td><input type="number" className="form-control-sm" id={Produit._id} defaultValue={stock}  onChange={(event) => StockUpdate(Produit.stock, event)} placeholder="Quantité"></input></td>
                <td>{Produit.prix_vente}</td>
                <td>{Produit.stock}</td>
            </tr>                
            )
            
        });
        
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    
    }   

    const getListAchat=async (result) => {
        setChargement(true);
        try {

        const apiUrl = process.env.REACT_APP_API_URL;
        const idfacture = currentfacture.map(facture => facture._id);
        const response = await axios.post(`${apiUrl}list/facture/${id}/${idfacture}`);
           response.data.produits.map(product => {
            const _id=product._id
            const designation=product.designation
            const stock=product.stock
            const prix_vente=product.prix_vente;
            const prix_achat=product.prix_achat;
            const tva=product.tva;
            
            const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
            if (existingProductIndex === -1) {
                const valeurs={_id,designation,stock,prix_vente,prix_achat,tva};
            listAchats.current.push({...valeurs});
            }
            const element = result.find(element => element._id ===_id);
            if(element){
              element.stock = parseInt(element.stock)+parseInt(product.stock)
            }
            

        });
        setListProduits(result);
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }

    const getProduits=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}produits/${id}`);
        getListAchat(response.data);
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }
      const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        const idcat=catField.current.value
        const idfournisseur = fournisseurField.current.value
        if(recherche!==rechercheInput){
        setRchercheInput(recherche)
         }
         if(idcat!==rechercheCat){
        setRchercheCat(idcat)
    }
    if(idfournisseur!==fournisseur){
        setFournisseur(idfournisseur)
    }
    }
    
    const ChargerAvoir =async (option) => {
        const dataclient=option
        if(dataclient['value']){
        const idclient = dataclient['value']

        try {
        const response = await axios.post(`${apiUrl}listavoirs/${id}/${idclient}`);
        
        const currentid=currentfacture[0].client[0].id
        const selectedId=currentClient.value

        if(currentid===selectedId){
            try {
            let avoir = ''
            currentfacture.map(facture => {
                 avoir={
                  "_id" : facture.avoir[0].id,
                  "numero" : facture.avoir[0].numero,
                  "montant" : facture.avoir[0].montant,          
                }
              })
              response.data.push({...avoir});
            }
            catch (error){

            }
        }
        setlistAvoirClient(response.data);
    }

        catch (error) {
            console.error('Error:', error);
        }
    }
}
    const SetCurrentAvoir = () =>{
        let avoir = avoirField.current.value
        avoir = listAvoirClient.find(element => element._id === avoir);
        if(avoir) {
            currentAvoir.current = {
                "id" : avoir._id,
                "numero" : avoir.numero,
                "montant" : avoir.montant,

            }
                }
        else{
            currentAvoir.current = ''
        }
    }
    const ShowFacture =() =>{
         dispatch(showapercu(true))
    }
    
    const GetFacture = async (idfacture,facture,date) =>{
        try {
            const dateFr=formatDateToFrench2(date)
            const response = await axios.get(`${apiUrl}generer/facture/${id}/${idfacture}`,{
              responseType: 'blob',
            });
        
            // Créez un lien pour télécharger le PDF
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Facture N${facture} ${dateFr}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
            catch (error) {
                console.error('Error generating invoice:', error);
              }
    }

    const Modifier = async () => {
        const dataclient=clientField.current.getValue()
        if(dataclient.length===0){
            alert('veuillez selectionner un client')
            return
        }
        const { value, label } = dataclient[0];
        let client = listClients.find(element => element._id === value);
        if(client)
            {
                client = [{
                    "id" : client._id,
                    "designation" : client.designation,
                    "ice" : client.ice,
                    "ville" : client.ville
                }]
            }

        let facture = factureField.current.value
        if(facture===""){
            facture=1;
        }
        const reglement = reglementField.current.value
        const livraison = livraisonField.current.value
        const etat = etatField.current.value
        const date = formatDateToIso(dateField.current.value)
        let avoir = avoirField.current.value
        avoir = listAvoirClient.find(element => element._id === avoir);
        if(avoir) {
            avoir = [{
                "id" : avoir._id,
                "numero" : avoir.numero,
                "montant" : avoir.montant,

            }]
        }
        const produits = removeZeroStock(listAchats)

        const totalvalue = calculateSum(listAchats.current);
        const total_brute = totalvalue
        let total = totalvalue
            try {
            total=parseFloat(total) - parseFloat(avoir["0"].montant)
        }
        catch (error) {
            console.error('Error:', error);
        }
        total = total.toFixed(2).toString();

        setEtat('');
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const idfacture = currentfacture[0]._id
        const response = await axios.post(`${apiUrl}edit/facture/${id}/${idfacture}`,{
                client,
                facture,
                reglement,
                livraison,
                total,
                total_brute,
                etat,
                date,
                produits,
                avoir,
            });
            if(response.data.status==="exist"){
                alert("Ce numéro de facture existe déjà");
            }
            else {
                GetFacture(response.data.idfacture,facture,date)
                dispatch(modifsuccess(true));
                navigate('/home/factures', { replace: true });
            }
        }
     catch (error) {
        console.error('Error:', error);
        setEtat('erreur');    
    }
    setChargement(false);
    }    

    const handleChange = selectedOption => {
        setcurrentClient(selectedOption);
              };

    useEffect(() =>{
        InstCurrentAvoir();
        getProduits();
        CheckPromo();
      },[])

      useEffect(() =>{
        SelectClient();
      },[currentfacture])

      useEffect(() =>{
        ChargerAvoir(currentClient);;
      },[currentClient])


  return (
    <div className="container-lg align-items-center">
        {Chargement? 
                <div d-flex className="d-flex justify-content-center mb-2">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }
                        {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                                </div>                 
                                :
                                ''                    
                        } 
<table className="table align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Client</th>
                    <th scope="col">N° facture</th>
                    <th scope="col">Mode réglement</th>
                    <th scope="col">Etat réglement</th>
                    <th scope="col">Etat livraison</th>
                    <th scope="col">Avoir</th>
                    <th scope="col">Date</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                        <Select options={optionClients} value={currentClient} className="form-control form-select-sm"  placeholder="Recherche client" ref={clientField} onChange={handleChange} /> 
                        </td>
                        <td><input type="number" className="form-control" id='facture' defaultValue={currentfacture.map(facture => facture.facture)}  ref={factureField}></input></td>
                        <td>
                        <select className="form-select" id="reglement" defaultValue={currentfacture.map(facture => facture.reglement)} ref={reglementField}>
                        <option value="Espèce">Espèce</option>
                        <option value="Chèque">Chèque</option>
                        <option value="Virement">Virement</option>
                        </select>                             
                        </td>

                        <td>
                        <select className="form-select" id="etat" defaultValue={currentfacture.map(facture => facture.etat)} ref={etatField}>
                        <option value="Réglée">Réglée</option>
                        <option value="Non Réglée">Non Réglée</option>
                        </select>                             
                        </td>

                        <td>
                        <select className="form-select" id="livraison" defaultValue={currentfacture.map(facture => facture.livraison)} ref={livraisonField}>
                        <option value="Livrée">Livrée</option>
                        <option value="Non Livrée">Non Livrée</option>
                        </select>                             
                        </td>
                        <td>
                        <select className="form-select" id="avoir" ref={avoirField} onChange={SetCurrentAvoir}>
                            <option value ="">Aucun</option>
                            <ShowAvoir/>
                            </select>
                        </td>
                        <td><input type="date" className="form-control" id="date" ref={dateField} defaultValue={formatDateToFrench(currentfacture['0'].date)} onChange={handleDateChange} ></input></td>   

                    </tr>
                </tbody>

</table>

<p className="h2 text-center">Produits</p>

<div className="d-flex justify-content-start mb-3 w-50" >
        <input type="text" className="form-control w-50 " id="designation"  placeholder="Recherche produit" ref={rechercheField} onKeyUp={Recherche} ></input>

        <select className="form-select form-select-sm w-25 ms-2" id="fournisseur" ref={fournisseurField} value={fournisseur} onChange={Recherche}>
          <option value="tout">Fournisseurs</option>
          <Listfournisseurs/>
</select>

<select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={catField} value={rechercheCat} onChange={Recherche}>
          <option value="tout">Catégories</option>
          <Catlist/>
</select>
</div>
<div class="table-responsive">
<table className="table align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Désignation</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Prix de vente</th>
                    <th scope="col">Stock</th>
                </tr>
            </thead>
            <tbody>
            <ShowList/>
            </tbody>
            </table>
            </div>
            <div className="d-flex justify-content-end mb-3">
            <button type="button" className="btn btn-primary" onClick={ShowFacture}>Aperçu</button>
            <button type="button" className="btn btn-success ms-3" onClick={Modifier}>Enregistrer & Imprimer</button>
      </div>
      <ModalApercu Achats ={listAchats} Total={calculateSum(listAchats.current)} Avoir={currentAvoir}/>
    </div>
  )
}
