import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    modalShow : false,
    modalModifShow : false,
    list :[],
    ajoutOk : false,
    modifOk : false,
    current :[],
    listClients : [],
}
const Avoirs = createSlice({
    name : 'AvoirsData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        getList:(state,action) =>{
            state.list=action.payload
        },
        current:(state,action) =>{
            state.current=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
        modif:(state,action) =>{
        state.modifOk=action.payload
},
        addOne:(state,action) =>{
            state.list.push(action.payload)
    },
        modalModifShow:(state,action) =>{
            state.modalModifShow=action.payload
},
        listclient:(state,action) =>{
            state.listClients=action.payload
        },
    update: (state, action) => {

        const element = state.list.find(element => element._id === action.payload.idavoir);
        if (element) {
            element.client = action.payload.client;
            element.montant = action.payload.montant;
            element.remarque = action.payload.remarque;
            element.etat = action.payload.etat;
            element.date = action.payload.date;

    }
  },
  remove: (state, action) => {    
    const id = action.payload;
    state.list=state.list.filter(element => element._id !== id);
  }

    }
})

export const {showmodal,getList,ajout,modif,addOne,modalModifShow,update,current,remove,listclient} = Avoirs.actions
export default Avoirs.reducer;