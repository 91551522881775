import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from "./UsersSlice";
import axios from 'axios';
export default function ModalModifier() {

    const Usersdata = useSelector(state => state.usersData);
    const currentelement = Usersdata.current;
    const modalShow = Usersdata.modalModifShow;
    const id = useSelector(state => state.userData.id);

    const [loading,setLoading] =useState(false)
    const [etat,setEtat] =useState('')
    const dispatch= useDispatch();

    const designationField = useRef('')
    const telField = useRef('')
    const salaireField = useRef('')

    const ChampUpdate = () =>{
        const _id=currentelement._id;
        const designation=designationField.current.value;
        const tel=telField.current.value;
        const salaire=salaireField.current.value;

        const data={_id,designation,tel,salaire};
        dispatch(current(data));
    }

    const Close = () => {
        dispatch(modalModifShow(false));
        setEtat('');
    }

    const Ajouter = async ()=> {
        const nom=designationField.current.value.trim()
        const tel=telField.current.value.trim()
        let salaire = salaireField.current.value
        salaire=salaire.replace(",", ".");
        const iduser = currentelement._id;

        if(nom==="")
            {
                alert("Désignation vide");
                return;
            }
            setEtat('')
            setLoading(true)
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}edit/user/${id}/${iduser}`, {
                nom,
                tel,
                salaire,
            });
            if(response.data.status==="ok")
                {
                    const data={iduser,nom,tel,salaire};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                }
        } catch (error) {
            setEtat('erreur');
        }
        setLoading(false)
    }

    useEffect(() =>{
        setEtat('');
      },[]);

      return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Modifier utilisateur</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }
                    <label className="form-label" for="designation">Nom complet</label>
                    <input type="text" className="form-control" id="designation" onChange={ChampUpdate} value={currentelement.nom}  ref={designationField}></input>
                    
                    <label className="form-label" for="tel">Tel.</label>
                    <input type="text" className="form-control" lang="en" id="tel" onChange={ChampUpdate} value={currentelement.tel} ref={telField}></input>

                    <label className="form-label" for="salaire">Salaire</label>
                    <input type="number" className="form-control" lang="en" id="salaire" ref={salaireField} onChange={ChampUpdate} value={currentelement.salaire}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
