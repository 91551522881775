import React from 'react';
import {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
export default function Stats() {
  const styles = {
    card: {
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginBottom: '1.5rem',
    },
    cardBody: {
      padding: '1.5rem',
    },
    media: {
      display: 'flex',
      alignItems: 'center',
    },
    image: {
      width : '60px',
      height : '60px',
      marginRight: '1rem',
    },
    textCheque: {
      textAlign: 'right',
      color: '#00B5B8',
    },
    textPromo: {
        textAlign: 'right',
        color: '#FFA808',
      },
      textFacture: {
        textAlign: 'right',
        color: '#FF7588',
      },
      textStock: {
        textAlign: 'right',
        color: '#16D39A',
      },
    h3: {
      marginBottom: '0.5rem',
      fontWeight: 'bold',
      
    },
    span: {
      fontSize: '1rem',
      color: '#6c757d',
      fontWeight: 'bold',
    },
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const id = useSelector(state => state.userData.id);
  const [EtatCheque, setEtatCheque] = useState("--");
  const [EtatPromo, setEtatPromo] = useState("--");
  const [EtatFacture, setEtatFacture] = useState("--");
  const [Etatstock, setEtatstock] = useState("--");

  const Getstats=async () => {
    try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const now = new Date();
    now.setHours(23, 59, 59, 999);
    const date = now.toISOString();
    now.setDate(now.getDate() - 1)
    const dateHier = now.toISOString()

    const response = await axios.post(`${apiUrl}dashstats/${id}/${date}/${dateHier}`);
    setEtatCheque(response.data.cheque)
    setEtatPromo(response.data.promo)
    setEtatFacture(response.data.facture)
    setEtatstock(response.data.stock)
    
    }
 catch (error) {
    console.error('Error:', error);    
}
}

  useEffect(() => {
    Getstats()
    const intervalId = setInterval(() => {
        Getstats()
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container-lg align-items-center">
      <div className="row mt-4">
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/cheque.png`} style={styles.image}  alt="cheque" />
                  <div style={styles.textCheque}>
                    <h3 style={styles.h3}>{EtatCheque}</h3>
                    <span style={styles.span}>Chèque en attente</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/promo.png`} style={styles.image}  alt="promo" />
                <div style={styles.textPromo}>
                    <h3 style={styles.h3}>{EtatPromo}</h3>
                    <span style={styles.span}>Promotion(s) active(s)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/facture.png`} style={styles.image}  alt="promo" />
                  <div style={styles.textFacture}>
                    <h3 style={styles.h3}>{EtatFacture}</h3>
                    <span style={styles.span}>Factures impayées</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/stock.png`} style={styles.image}  alt="promo" />
                  <div style={styles.textStock}>
                    <h3 style={styles.h3}>{Etatstock}</h3>
                    <span style={styles.span}>Produits en rupture</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
