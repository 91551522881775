import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export default function Setup2fa() {
    
    const id = useSelector(state => state.userData.id);
    const apiUrl = process.env.REACT_APP_API_URL;

    const QrKey=useRef('');
    const CodeField =useRef('');

    const [imageSrc, setImageSrc] = useState(null);
    const [ErreurCode, setErreurCode] = useState(false);

    const navigate = useNavigate();

    const GenererQr=async () => {
        try {
            const key = QrKey.current
            const imageUrl = `${apiUrl}generate/qrcode/${id}/${key}`
            const response = await axios.get(imageUrl, { responseType: 'blob' });
            const imageBlob = new Blob([response.data]);
            const imageObjectURL = URL.createObjectURL(imageBlob);
            setImageSrc(imageObjectURL);
        }
        catch (error) {
            console.error('Error:', error);    
        }
        }

    const GenererKey=async () => {
    try{
        const response = await axios.post(`${apiUrl}generate/key/${id}`);
        const key = response.data.key
        QrKey.current=key
        GenererQr()

    }
    catch (error) {
        console.error('Error:', error);    
    }
    }

    const Coupler=async () => {
        setErreurCode(false)
        const key=QrKey.current
        const code = CodeField.current.value
        try {
        const response = await axios.post(`${apiUrl}coupler/${id}/${key}/${code}`);
          if(response.data.status==="ok"){
            const token = response.data.token
            Cookies.set('token', token, { 
                expires: 30,
                secure: true, 
                sameSite: 'Strict' 
              })
              navigate('/home');
          }
          else {
            setErreurCode(true)
          }
        }
     catch (error) {
        console.error('Error:', error);
    }
    }

    useEffect(() =>{
        GenererKey();
        },[]);
  return (
    <div className="container-lg align-items-center">
<section className="mt-4">
<p className="h2 text-center">Configuration de l'authentification à deux facteurs</p>
<p className="mt-4">L'authentification à deux facteurs (2FA) ajoute une couche supplémentaire de sécurité à votre compte en vous demandant d'entrer un code provenant de votre appareil en plus de votre mot de passe lorsque vous vous connectez.</p>
<p className="mt-4">Pour configurer la 2FA, vous devrez installer une application 2FA sur votre appareil. Il existe de nombreuses applications 2FA disponibles, telles que Google Authenticator, Authy et Microsoft Authenticator.</p>
<p className="mt-4">Une fois que vous avez installé une application 2FA, vous devrez scanner le code QR ci-dessous en utilisant l'application. Le code QR contient une clé secrète qui sera utilisée pour générer les codes 2FA.</p>

<div d-flex className="d-flex justify-content-center">
<img src={imageSrc} width="200" height="200" alt="QR Code"></img>
</div>

<p className="mt-4">Une fois que vous avez installé une application 2FA, vous devrez scanner le code QR ci-dessous en utilisant l'application. Le code QR contient une clé secrète qui sera utilisée pour générer les codes 2FA.</p>

<div d-flex className="d-flex flex-column align-items-center">
{ErreurCode ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Code incorrecte. Veuillez réessayer</strong>
                            </div>  
                            : 
                            ''
                            }
<input type="text" className="form-control w-50" id="client"  placeholder="Entrez le code généré" ref={CodeField} ></input>
<button type="button" className="btn btn-success mt-4 mb-2" onClick={Coupler}>Soumettre</button>
</div>

</section>
        </div>
  )
}
