import {useState,useEffect,} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove } from "./CategoriesSlice";
import ModalAjouter from './ModalAjouter';
import ModalModifer from './ModalModifier';

export default function Categories() {
    const [Chargement, setChargement] = useState(false);
    const ajoutSuccess=useSelector(state => state.categoriesData.ajoutOk);
    const [removeSuccess, setRemoveSuccess] = useState(false);

    const id = useSelector(state => state.userData.id);
    const listCategorie=useSelector(state => state.categoriesData.list);
    const modifSuccess=useSelector(state => state.categoriesData.modifOk);
    const dispatch= useDispatch();

    const getCategories=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}categories/${id}`);
        dispatch(getList(response.data));
        }
     catch (error) {
        console.error('Error:', error);
    }
    setChargement(false);
    }

    const Ajouter = () => dispatch(showmodal(true));

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = listCategorie.find(categorie => categorie._id === id);
        dispatch(current(valeur));
    };

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
        }, 5000);
    }

    const suppression = async (idcat)=> {

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/categorie/${id}/${idcat}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idcat));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
    }

    const supprimer = (idcat) => {
        let valeur = listCategorie.find(categorie => categorie._id === idcat);
        valeur=valeur.designation
        let result = window.confirm(`Supprimer la catégorie ${valeur} ?`);
        if (result === true) {
            suppression(idcat);
        }
    }
    
    function ShowList() {
        HideSuccessMsg();
        if(listCategorie.length > 0){
        return listCategorie.map(Categorie => {
            return(
                <tr key={Categorie._id}>
                <td>{Categorie.designation}</td>
                <td>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(Categorie._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(Categorie._id)}>Supprimer</button>
                </td>
            </tr>                
            )
        })
    }
    else{
        return <tr>
            <td colSpan={5} align='center'>Pas de données</td>
        </tr>;
    }
    }


useEffect(() =>{
    getCategories();
    dispatch(showmodal(false));
    },[]);

  return (
    <>
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Catégories</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter une catégorie
    </button>
  </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Catégorie ajoutée avec succès</strong>
        </div>                 
            :
            ''                    
        }

{modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Catégories modifiée avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Catégorie supprimée avec succès</strong>
        </div>                 
            :
            ''                    
        }
<div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Désignation</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
</section>
    </div>
    <ModalAjouter />
    <ModalModifer />
</>
    
  )

}
