import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    modalShow : false,
    list :[],
    ajoutOk : false,
    listProduits : [],
}

const Promos = createSlice({
    name : 'PromosData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        getList:(state,action) =>{
            state.list=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
        addOne:(state,action) =>{
            state.list.push(action.payload)
    },
    listproduit:(state,action) =>{
        state.listProduits=action.payload
    },
  remove: (state, action) => {    
    const id = action.payload;
    state.list=state.list.filter(element => element._id !== id);
  }

    }
})

export const {showmodal,getList,ajout,addOne,remove,listproduit} = Promos.actions
export default Promos.reducer;