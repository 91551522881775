import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,ajout,addOne} from "./PromosSlice";
import axios from 'axios';
import Select from 'react-select';


export default function Impressiongroupe() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const dispatch= useDispatch();
    const id = useSelector(state => state.userData.id);
    const Promodata = useSelector(state => state.promosData);
    const listProduits = Promodata.listProduits;
    const modalShow = Promodata.modalShow;
    const [etat,setEtat] =useState('')
    const [loading,setLoading] =useState(false)

    const DatedebutField= useRef('')
    const DateFinField = useRef('')
    const ProduitField = useRef('')

    const ValeurField = useRef('')
    const RemarqueField = useRef('')

    const ViderChamps = () => {
        ValeurField.current.value = '';
        RemarqueField.current.value = '';

    }

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selectedDate2, setSelectedDate2] = useState(todayDate);

    const formatDateToIso = (dateString,type) => {
        const formattedDate = new Date(`${dateString}T00:00:00Z`);
        if(type==="fin"){
            formattedDate.setHours(23, 59, 59, 999);
        }
        return formattedDate.toISOString();
      };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    
      const handleDateChange2 = (event) => {
        setSelectedDate2(event.target.value);
      };  

    const Close = () => dispatch(showmodal(false));

    const Ajouter = async ()=> {
        const dataproduit=ProduitField.current.getValue()
        const { value, label } = dataproduit[0];
        const produit={
            "id" : value,
            "designation" :label,
        }
        let valeur = ValeurField.current.value;
        valeur=valeur.replace(",", ".");
        const remarque = RemarqueField.current.value.trim()
        const date_debut = formatDateToIso(DatedebutField.current.value,'debut')
        const date_fin = formatDateToIso(DateFinField.current.value,'fin')
        setEtat('')
        setLoading(true)
        try {
            const response = await axios.post(`${apiUrl}new/promo/${id}`, {
                produit,
                valeur,
                remarque,
                date_debut,
                date_fin,
            });
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,produit,valeur,remarque,date_debut,date_fin};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    ViderChamps();
                }
                else{
                    setEtat('erreur');
                }
            } catch (error) {
                setEtat('erreur');
            }
            setLoading(false)
    }
    useEffect(() =>{
        setSelectedDate(todayDate);
        setSelectedDate2(todayDate);
        ViderChamps();
        setEtat('');

      },[modalShow])
    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter une promotion</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }
                    <label className="form-label" for="produit">Produit</label>
                    <Select options={listProduits} placeholder="Rechercher un produit" ref={ProduitField} />
                    <label className="form-label" for="pourcentage">Pourcentage (%)</label>
                    <input type="number" className="form-control" lang="en" id="pourcentage" ref={ValeurField}></input>

                    <label className="form-label" for="debut">Date debut</label>
                    <input type="date" className="form-control" id="debut" value={selectedDate} onChange={handleDateChange}  ref={DatedebutField}></input>
                    
                    <label className="form-label" for="fin">Date fin</label>
                    <input type="date" className="form-control" id="fin" value={selectedDate2} onChange={handleDateChange2}  ref={DateFinField}></input>

                    <label className="form-label" for="remarque">Remarque</label>
                    <input type="text" className="form-control" lang="en" id="remarque" ref={RemarqueField}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
