import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    modalShow : false,
    modalModifShow : false,
    listfournisseur :[],
    ajoutOk : false,
    modifOk : false,
    currentFournisseur :[],
}
const Fournisseurs = createSlice({
    name : 'FournisseursData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        getList:(state,action) =>{
            state.listfournisseur=action.payload
        },
        currentfournisseur:(state,action) =>{
            state.currentFournisseur=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
        modif:(state,action) =>{
        state.modifOk=action.payload
},
        addOne:(state,action) =>{
            state.listfournisseur.push(action.payload)
    },
        modalModifShow:(state,action) =>{
            state.modalModifShow=action.payload
},
    updateFournisseur: (state, action) => {

        const client = state.listfournisseur.find(client => client._id === action.payload.idfournisseur);
        if (client) {
        client.designation = action.payload.designation;
        client.compte = action.payload.compte;
        client.tel = action.payload.tel;
        client.adresse = action.payload.adresse;
        client.email = action.payload.email;
    }
  },
  removeFournisseur: (state, action) => {    
    const id = action.payload;
    state.listfournisseur=state.listfournisseur.filter(client => client._id !== id);
  }


    }
})

export const {showmodal,getList,ajout,modif,addOne,modalModifShow,updateFournisseur,currentfournisseur,removeFournisseur} = Fournisseurs.actions
export default Fournisseurs.reducer;