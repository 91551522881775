import {useState,useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import {ajoutsuccess,showapercu,listclient} from './RetourSlice';
import ApercuRetour from './ApercuRetour';
export default function RetourStock() {
    const Retourdata = useSelector(state => state.retoursData);
    const ajoutSuccess = Retourdata.ajoutsuccess;
    const id = useSelector(state => state.userData.id);

    const [Chargement, setChargement] = useState(false);
    const [Listretours, setListretours] = useState([]);
    const [removeSuccess, setRemoveSuccess] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    const listAchats=useRef([]);

    const navigate = useNavigate();
    const dispatch= useDispatch();

    const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };

      const ShowRetour=async (idretour) => {
        try {
        const response = await axios.post(`${apiUrl}list/retourstock/${id}/${idretour}`);
        listAchats.current = response.data.produits
        dispatch(showapercu(true))
        }
        catch (error) {
            console.error('Error:', error);    
        }
      }

      const GetClients=async () => {
        try {
        const response = await axios.post(`${apiUrl}clients/${id}`);
          dispatch(listclient(response.data));
        }
     catch (error) {
        console.error('Error:', error);
    }
    }

    const Ajouter=() => {
        navigate('/home/stock/nouvelretour');

        }

        const suppression = async (idretour)=> {

            try {
                
                const response = await axios.post(`${apiUrl}delete/retourstock/${id}/${idretour}`);
                if(response.data.status==="ok")
                    {
                        setListretours(Listretours.filter(element => element._id !== idretour))
                        setRemoveSuccess(true);
                        HideSuccessMsg();
                    }
            } catch (error) {
                console.log(error)
            }
          }

          const supprimer = (idretour) => {

            let result = window.confirm(`Supprimer ce retour ?`);
            if (result === true) {
                suppression(idretour);
            }
          }

        const HideSuccessMsg = () => {
            setTimeout(() => {
                dispatch(ajoutsuccess(false));
                setRemoveSuccess(false);
            }, 5000);
        } 

        const GetRetours=async () => {
            setChargement(true);
            try {
            const response = await axios.post(`${apiUrl}retours/${id}`);
            setListretours(response.data);
            }
         catch (error) {
            console.error('Error:', error);    
        }
        setChargement(false);
        }

        function ShowList() {
            HideSuccessMsg();
            if(Listretours.length > 0){
            return Listretours.map(Retour => {
                return(
                    <tr key={Retour._id}>
                    <td>{Retour.client.designation}</td>
                    <td>{Retour.facture}</td>
                    <td>{Retour.remarque}</td>
                    <td>{Retour.date!=="" ? formatDateToFrench(Retour.date) : ''}</td>
                    <td>
                    <button type="button" className="btn btn-primary me-4" onClick={() =>ShowRetour(Retour._id)}>Aperçu</button>
                    <button type="button" class="btn btn-danger" onClick={() => supprimer(Retour._id)}>Supprimer</button>
                    </td>
                </tr>                
                )
            })
        }
        else{
            return <tr>
                <td colSpan={5} align='center'>Pas de données</td>
            </tr>;
        }
        }
        useEffect(() =>{
            HideSuccessMsg();
            GetClients();
            GetRetours();
          },[])

  return (
    <>
    <div className="container-sm align-items-center">

    <section className="mt-4">
    <p className="h2 text-center">Retour stock</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

{ajoutSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Retour stock ajouté avec succès</strong>
            </div>                 
                :
                ''                    
            }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Retour stock suprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }
                <div className="d-flex justify-content-end mb-3">
        
        <button className="btn btn-primary mr-2" onClick={Ajouter}>
          <i className="fas fa-user-plus"></i> Ajouter un retour
        </button>
      </div>
      <div class="table-responsive">
            <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Client</th>
                <th scope="col">N° Facture</th>
                <th scope="col">Remarque</th>
                <th scope="col">Date</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
    <ApercuRetour Achats={listAchats} />
    </section>
    </div>
    </>
  )
}
