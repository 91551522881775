import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from "./ProduitsSlice";
import axios from 'axios';
import Catlist from './Catlist';
import Fournisseurs from './Fournisseur';

export default function ModalModifer(Idcat) {

    const Produitdata = useSelector(state => state.produitsData);
    const currentelement = Produitdata.current;
    const modalShow = Produitdata.modalModifShow;
    const id = useSelector(state => state.userData.id);

    const [loading,setLoading] =useState(false)
    const [etat,setEtat] =useState('')
    const [idcat,setIdcat] =useState(Idcat)

    const designationField=useRef()
    const catField=useRef()
    const fournissField=useRef('')
    const prixvField=useRef()
    const prixaField=useRef()
    const tvaField=useRef()

    const ChampUpdate = () =>{
        const _id=currentelement._id;
        const designation=designationField.current.value;
        const idfournisseur=fournissField.current.value
        let fournisseur = fournissField.current.options[fournissField.current.selectedIndex].text
        fournisseur=[{
            "id" : idfournisseur,
            "designation" :fournisseur,
        }]
        const idcategorie=catField.current.value;
        let categorie = catField.current.options[catField.current.selectedIndex].text
        categorie=[{
            "id" : idcategorie,
            "designation" :categorie,
        }]
        let prix_vente=prixvField.current.value.trim()
        prix_vente=prix_vente.replace(",", ".");  
        let prix_achat=prixaField.current.value.trim()
        prix_achat=prix_achat.replace(",", ".");
        let tva=tvaField.current.value.trim()
        tva=tva.replace(",", ".");
        const data={_id,designation,fournisseur,categorie,prix_vente,prix_achat,tva};
        dispatch(current(data));
        setIdcat(idcategorie);
    }

    const Ajouter = async ()=> {
        const designation=designationField.current.value.trim()
        const idfournisseur=fournissField.current.value
        let fournisseur = fournissField.current.options[fournissField.current.selectedIndex].text
        fournisseur=[{
            "id" : idfournisseur,
            "designation" :fournisseur,
        }]
        const idcategorie=catField.current.value;
        let categorie = catField.current.options[catField.current.selectedIndex].text
        categorie=[{
            "id" : idcategorie,
            "designation" :categorie,
        }];
        let prix_vente=prixvField.current.value.trim()
        prix_vente=prix_vente.replace(",", ".");

        let prix_achat=prixaField.current.value.trim()
        prix_achat=prix_achat.replace(",", ".");

        let tva=tvaField.current.value.trim()
        tva=tva.replace(",", ".");

        if(designation==="")
            {
                alert("Désignation vide");
                return;
            }
            setEtat('')
            setLoading(true)
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const idproduit = currentelement._id;
            const response = await axios.post(`${apiUrl}edit/produit/${id}/${idproduit}`, {
                designation,
                fournisseur,
                categorie,
                prix_vente,
                prix_achat,
                tva,
            });
            if(response.data.status==="ok")
                {
                    const data={idproduit,designation,fournisseur,categorie,prix_vente,prix_achat,tva};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                }
            else{
                setEtat('erreur');
            }
        } catch (error) {
            setEtat('erreur');
        }
        setLoading(false)
    }

    const dispatch= useDispatch();
    const Close = () => {
        dispatch(modalModifShow(false));
        setEtat('');
        
    };

    useEffect(() =>{
        setEtat('');
      },[]);

    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Modifier produit</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }
                    <label className="form-label" for="designation">Désignation</label>
                    <input type="text" className="form-control" id="designation" onChange={ChampUpdate} value={currentelement.designation} placeholder="Désignation du produit" ref={designationField}></input>

                    <label className="form-label" for="fournisseur">Fournisseur</label>
                    <select className="form-select form-select-sm" id="fournisseur" ref={fournissField}>
                    <Fournisseurs/>
                    </select>

                    <label className="form-label" for="categorie">Catégorie</label>
                    <select className="form-select form-select-sm" id="categorie" ref={catField} value={idcat} onChange={ChampUpdate}>

                    <Catlist/>
                    </select>
                    <label className="form-label" for="designation">Prix de vente</label>
                    <input type="number" className="form-control" id="prixv" onChange={ChampUpdate} value={currentelement.prix_vente} placeholder="Prix de vente" ref={prixvField}></input>

                    <label className="form-label" for="designation">Prix d'achat</label>
                    <input type="number" className="form-control" id="prixa" onChange={ChampUpdate} value={currentelement.prix_achat} placeholder="Prix d'achat" ref={prixaField}></input>

                    <label className="form-label" for="designation">TVA(%)</label>
                    <input type="number" className="form-control" id="tva" onChange={ChampUpdate} value={currentelement.tva} placeholder="TVA(%)" ref={tvaField}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    }


