import {useState,useEffect,} from 'react';
import axios from 'axios';
import ModalAjouter from './ModalAjouter';
import { useDispatch,useSelector } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,currentfournisseur,removeFournisseur } from "./FournisseursSlice";
import ModalModifer from './ModalModifier';


export default function Fournisseurs() {
    
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [Chargement, setChargement] = useState(false);

    const id = useSelector(state => state.userData.id);
    const listFournisseur=useSelector(state => state.fournisseursData.listfournisseur);
    const ajoutSuccess=useSelector(state => state.fournisseursData.ajoutOk);
    const modifSuccess=useSelector(state => state.fournisseursData.modifOk);
    const dispatch= useDispatch();

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = listFournisseur.find(fournisseur => fournisseur._id === id);
        dispatch(currentfournisseur(valeur));
    };

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
        }, 5000);
    }

    const suppression = async (idfournisseur)=> {

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/fournisseur/${id}/${idfournisseur}`);
            if(response.data.status==="ok")
                {
                    dispatch(removeFournisseur(idfournisseur));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
    }

    const supprimer = (idfournisseur) => {
        let valeur = listFournisseur.find(fournisseur => fournisseur._id === idfournisseur);
        valeur=valeur.designation
        let result = window.confirm(`Supprimer le fournisseur ${valeur} ?`);
        if (result === true) {
            suppression(idfournisseur);
        }
    }


    function ShowFournisseurs() {
        HideSuccessMsg();
        if(listFournisseur.length > 0){
        return listFournisseur.map(Fournisseur => {
            return(
                <tr key={Fournisseur._id}>
                <td>{Fournisseur.designation}</td>
                <td>{Fournisseur.compte}</td>
                <td>{Fournisseur.tel}</td>
                <td>{Fournisseur.adresse}</td>
                <td>{Fournisseur.email}</td>
                <td>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(Fournisseur._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(Fournisseur._id)}>Supprimer</button>
                </td>
            </tr>                
            )
        })
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    }

    const Ajouter = () => dispatch(showmodal(true));

    const getFournisseurs=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}fournisseurs/${id}`);
        dispatch(getList(response.data));
        }
     catch (error) {
        console.error('Error:', error);
    }
    setChargement(false);
    }

    useEffect(() =>{
        getFournisseurs();
        dispatch(showmodal(false));
      },[]);
  return (
    <>
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Fournisseurs</p>
    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }
    <div className="d-flex justify-content-end mb-3">
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter un fournisseur
    </button>
  </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Fournisseur ajouté avec succès</strong>
        </div>                 
            :
            ''                    
        }

{modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Fournisseur modifié avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Fournisseur supprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }
        <div class="table-responsive">

  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Désignation</th>
                <th scope="col">Compte</th>
                <th scope="col">Tel.</th>
                <th scope="col">Adresse</th>
                <th scope="col">E-Mail</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <ShowFournisseurs/>
        </tbody>
    </table>
    </div>
</section>
    </div>
    <ModalAjouter />
    <ModalModifer />
</>
    
  )
}
