import {useState,useEffect,useRef} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
export default function Parametres() {

    const [Chargement,setChargement] =useState(false)
    const [ErrorPass,setErrorPass] =useState(false)
    const [LenPass,setLenPass] =useState(false)
    const [ErrorConfirm,setErrorConfirm] =useState(false)
    const [ModifSuccess, setModifSuccess] = useState(true);

    const id = useSelector(state => state.userData.id);

    const currentPasswordField = useRef('')
    const newPasswordField = useRef('')
    const confirmPasswordField = useRef('')

    const Changer = async ()=> {
        setErrorPass(false)
        setLenPass(false)
        setErrorConfirm(false)
        setModifSuccess(false)
        const currentpassword = currentPasswordField.current.value.trim()
        const newpassword = newPasswordField.current.value.trim()
        const confirmpassword = confirmPasswordField.current.value.trim()
        if (currentpassword==="" || newpassword==="" || confirmpassword===""){
            alert('Vous remplir tous les champs')
            return
        }
        if(newpassword.length<8){
            setLenPass(true)
            return
        }
        if(newpassword!==confirmpassword){
            setErrorConfirm(true)
            return
        }

        setChargement(true)
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}edit/password/${id}`, {
                currentpassword,
                newpassword,
            });

            if(response.data.status==="ok"){
                setModifSuccess(true)
            }
            if(response.data.status==="incorrect"){
                setErrorPass(true)
            }

        }
     catch (error) {
    }
        setChargement(false)
        
    }

    useEffect(() =>{
        setErrorPass(false)
        setLenPass(false)
        setErrorConfirm(false)
        setModifSuccess(false)
        },[]);
  return (
    <div className="container-lg align-items-center h-100">

    <div class="row justify-content-center align-items-center h-100">
    {Chargement? 
                <div d-flex className="d-flex justify-content-center mt-3">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }
        <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
    
                <div class="card-body p-4">
                    <div class="text-center mt-2">
                        <h5 class="text-primary">Changement de mot de passe</h5>
                    </div>
                    <div class="p-2 mt-4">
                    {ModifSuccess?
                                    <div class="alert alert-success  mb-4 text-center" role="alert">
                                    <strong>Mot de passe modifié avec succès</strong>
                                </div>                 
                            :
                            ''                    
                        }
                            {ErrorPass?
                                    <div class="alert alert-danger  mb-4 text-center" role="alert">
                                    <strong>Mot de passe incorrecte</strong>
                                </div>                 
                            :
                            ''                    
                        }
                                {LenPass?
                                    <div class="alert alert-danger  mb-4 text-center" role="alert">
                                    <strong>Le mot de passe doit contenir au moins 8 caractères</strong>
                                </div>                 
                            :
                            ''                    
                        }
                                {ErrorConfirm?
                                    <div class="alert alert-danger  mb-4 text-center" role="alert">
                                    <strong>Les mots de passe ne correspondent pas. Veuillez réessayer</strong>
                                </div>                 
                            :
                            ''                    
                        }
                            <div class="mb-3">
                                <div className="float-end">
                                </div>
                                <label className="form-label mb" for="currentpassword">Mot de passe actuel</label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                <input type="password" className="form-control pe-5" id="currentpassword" ref={currentPasswordField}></input>
                                </div>
                                <label className="form-label mb" for="newpassword">Nouveau mot de passe</label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                <input type="password" className="form-control pe-5" id="newpassword" ref={newPasswordField}></input>
                                </div>
                                <label className="form-label mb" for="confirmpassword">confirmer le nouveau mot de passe</label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                <input type="password" className="form-control pe-5" id="confirmpassword" ref={confirmPasswordField}></input>
                                </div>
                                <div className="mt-4">
                        <button type="button" className="btn btn-success w-100" onClick={Changer}>Changer le mot de passe</button>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            </div>
  )
}
