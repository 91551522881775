import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove } from "./UsersSlice";
import ModalAjouter from './ModalAjouter';
import ModalModifer from './ModalModifier';


export default function Utilisateurs() {
    const ajoutSuccess=useSelector(state => state.usersData.ajoutOk);
    const [Chargement, setChargement] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [resetPassSuccess, setresetPassSuccess] = useState(false);
    const [reset2faSuccess, setreset2faSuccess] = useState(false);
    const id = useSelector(state => state.userData.id);
    const listUsers=useSelector(state => state.usersData.list);

    const dispatch= useDispatch();

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
            setresetPassSuccess(false);
            setreset2faSuccess(false);
        }, 5000);
    }
    const Ajouter = () => dispatch(showmodal(true));

    const getUsers=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}users/${id}`);
        dispatch(getList(response.data));
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }

    const resetPassword = async (iduser)=> {
        setChargement(true);

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}reset/password/${id}/${iduser}`);
            if(response.data.status==="ok")
                {
                    setresetPassSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
        setChargement(false);
    }

    const resetPasswordConfirm = (iduser,nom) => {
        let result = window.confirm(`Réinitialiser le mot de passe de ${nom} ?`);
        if (result === true) {
            resetPassword(iduser);
        }
    }

    const reset2FA = async (iduser)=> {
        setChargement(true);

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}reset/2fa/${id}/${iduser}`);
            if(response.data.status==="ok")
                {
                    setreset2faSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
        setChargement(false);
    }

    const reset2faConfirm = (iduser,nom) => {
        let result = window.confirm(`Réinitialiser le 2FA de ${nom} ?`);
        if (result === true) {
            reset2FA(iduser);
        }
    }

    const suppression = async (iduser)=> {

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/user/${id}/${iduser}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(iduser));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
    }

    const supprimer = (iduser,nom) => {

        let result = window.confirm(`Supprimer le client ${nom} ?`);
        if (result === true) {
            suppression(iduser);
        }
    }

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = listUsers.find(client => client._id === id);
        dispatch(current(valeur));
    };

    function ShowList() {
        HideSuccessMsg();
        if(listUsers.length > 0){
        return listUsers.map(User => {
            return(
                <tr key={User._id}>
                <td>{User.nom}</td>
                <td>{User.tel}</td>
                <td>{User.salaire} Dhs</td>
                <td>
                <button type="button" class="btn btn-secondary me-4" onClick={() => resetPasswordConfirm(User._id,User.nom)}>Réinitialiser mot de passe</button>
                <button type="button" class="btn btn-info me-4" onClick={() => reset2faConfirm(User._id,User.nom)}>Réinitialiser 2FA</button>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(User._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(User._id,User.nom)}>Supprimer</button>
                </td>
            </tr>                
            )
        })
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    }

    useEffect(() =>{
        getUsers();
        dispatch(showmodal(false));
        },[]);
return (
    <>
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Utilisateurs</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter un utilisateur
    </button>
  </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Client ajouté avec succès</strong>
        </div>                 
            :
            ''                    
        }

{/* {modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Utilisateur modifié avec succès</strong>
        </div>                 
            :
            ''                    
        } */}

{resetPassSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Mot de passe réinitialisé avec succès</strong>
        </div>                 
            :
            ''                    
        }

{reset2faSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>2FA réinitialisé avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Utilisateur supprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }
        <div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Désignation</th>
                <th scope="col">Tel</th>
                <th scope="col">Salaire</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
</section>
    </div>
    <ModalAjouter />
    <ModalModifer />
</>
    
  )
}
