import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import fournisseursReducer from "./fournisseurs/FournisseursSlice";
import categoriesReducer from "./categories/CategoriesSlice";
import produitsReducer from "./produits/ProduitsSlice";
import achatsReducer from "./achats/AchatsSlice";
import clientsReducer from "./clients/ClientsSlice";
import FacturesReducer from "./factures/FactureSlice";
import AvoirsReducer from "./avoirs/AvoirsSlice";
import PromosReducer from "./promotions/PromosSlice";
import RetoursReducer from "./stock/RetourSlice";
import EchantillonsReducer from "./stock/EchantillonSlice"
import UsersReducer from "./users/UsersSlice"
import ChequesReducer from "./cheques/ChequesSlice";
import ChargesReducer from "./charges/ChargesSlice";

export const store=configureStore({
    reducer :{
        userData : userReducer ,
        fournisseursData : fournisseursReducer,
        categoriesData : categoriesReducer,
        produitsData : produitsReducer,
        achatsData : achatsReducer,
        clientsData : clientsReducer,
        facturesData : FacturesReducer,
        avoirsData : AvoirsReducer,
        promosData : PromosReducer,
        retoursData : RetoursReducer,
        echantillonsData : EchantillonsReducer,
        usersData : UsersReducer,
        chequesData : ChequesReducer,
        chargesData : ChargesReducer,
    }
});