import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    chequeShow : false,
    apercuShow : false,
    listAvoir : [],
    ajoutsuccess : false,
    modifsuccess : false,
    listClients : [],
    list :[],
    currentFacture : [],
    modalShow : false,
}
const Factures = createSlice({
    name : 'FacturesData',
    initialState,
    reducers :{
        showcheque:(state,action) =>{
            state.chequeShow=action.payload
        },  
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },   
        modifsuccess:(state,action) =>{
            state.modifsuccess=action.payload
        },  
        listclient:(state,action) =>{
            state.listClients=action.payload
        },
        getList:(state,action) =>{
            state.list=action.payload
        },
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        }, 
        idfacture:(state,action) =>{
            state.currentFacture=action.payload
        },
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
    }
})

export const {ajoutsuccess,listclient,getList,showapercu,idfacture,modifsuccess,showmodal} = Factures.actions;
export default Factures.reducer;