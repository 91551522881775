import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    id : '',
    nom : '',
    type : ''

}
const userSlice = createSlice({
    name : 'userData',
    initialState,
    reducers :{
        creation:(state,action) =>{
            state.id=action.payload._id
            state.nom=action.payload.nom
            state.type=action.payload.type
        }
    }

})

export const {creation} = userSlice.actions
export default userSlice.reducer;