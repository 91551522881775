import { useSelector,useDispatch } from 'react-redux';
import { showapercu } from "./AchatsSlice";
export default function Apercu({Achats}) {

    const RetoursData = useSelector(state => state.achatsData);
    const modalShow = RetoursData.apercuShow;

    const dispatch= useDispatch();

    function ShowList() {

        const listFiltre = Achats.current.filter(achat => achat.stock>0)
        return(
            listFiltre.map(achat =>{
                return(
                    <>
                    <tr key={achat._id}>
                        <td>{achat.designation}</td>
                        <td>{achat.stock}</td>
                    </tr>
                    </>
                )

            })
        )
    }
    const Close = () => {
        dispatch(showapercu(false));   
    };   

  return (
    <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Aperçu de l'achat</h5>
            </div>
            <div className="modal-body">
            <div class="table-responsive">
            <table className="table  align-middle table-dark table-striped mb-3">
    <thead>
        <tr>
            <th scope="col">Produit</th>
            <th scope="col">Quantité</th>
        </tr>
    </thead>
    <tbody>
        <ShowList/>
    </tbody>
</table>
</div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={Close}>Fermer</button>

            </div>
        </div>
    </div>
</div>  )
}
