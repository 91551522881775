import {useState,useEffect} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function Fournisseurs() {
    const [fournisseurList,setfournisseurList] =useState([])
    const id = useSelector(state => state.userData.id);

    function FournisseurList(){

        return fournisseurList.map((Fournisseur,index) => {
            return(
                <option key={index} value={Fournisseur._id}>
                    {Fournisseur.designation}
                    </option>
            );
        });
    }
    const getFournisseurs=async () => {
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}fournisseurs/${id}`);
        setfournisseurList(response.data);
        }
     catch (error) {
        console.error('Error:', error);
    }
    }
    useEffect(() =>{
        getFournisseurs();
        },[]);
  return (
    <FournisseurList/>
  )
}
