import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,ajout,addOne } from "./UsersSlice";
import axios from 'axios';
export default function ModalAjouter() {

    const usersData = useSelector(state => state.usersData);
    const modalShow = usersData.modalShow;
    const id = useSelector(state => state.userData.id);

    const dispatch= useDispatch();

    const [loading,setLoading] =useState(false)
    const [etat,setEtat] =useState('')

    const designationField = useRef('')
    const telField = useRef('')
    const salaireField = useRef('')

    const ViderChamps = () => {
        designationField.current.value = '';
        telField.current.value = '';
        salaireField.current.value = '';
    }

    const Close = () => {
        dispatch(showmodal(false));
        ViderChamps();
        setEtat('');
        
    };

    const Ajouter = async ()=> {
        const nom=designationField.current.value.trim()
        const tel=telField.current.value.trim()
        let salaire = salaireField.current.value
        salaire=salaire.replace(",", ".");
        const type = "user"
        const totp = ""

        if(nom==="")
            {
                alert("Désignation vide");
                return;
            }
            setEtat('')
            setLoading(true)
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}new/user/${id}`, {
                nom,
                tel,
                salaire,
                type,
                totp,
            });
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,nom,tel,salaire,type,totp};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    ViderChamps();
                }
            else if(response.data.status==="exist")
             {
                alert("Utilisateur déja existant");
            }
            else{
                setEtat('erreur');
            }
        } catch (error) {
            setEtat('erreur');
        }
        setLoading(false)
    }

    useEffect(() =>{
        ViderChamps();
        setEtat('');
      },[]);

    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter un utilisateur</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }
                    <label className="form-label" for="designation">Nom complet</label>
                    <input type="text" className="form-control" id="designation"  ref={designationField}></input>
                    
                    <label className="form-label" for="tel">Tel.</label>
                    <input type="text" className="form-control" lang="en" id="tel" ref={telField}></input>

                    <label className="form-label" for="salaire">Salaire</label>
                    <input type="number" className="form-control" lang="en" id="salaire" ref={salaireField}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
