import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    apercuShow : false,
    ajoutsuccess : false,

}

const Echantillons = createSlice({
    name : 'EchantillonsData',
    initialState,
    reducers :{
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        }, 
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },  
    }
})
export const {showapercu,ajoutsuccess} = Echantillons.actions;
export default Echantillons.reducer;