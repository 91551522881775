import {useState,useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import {ajoutsuccess,showapercu} from './EchantillonSlice';
import EchantillonRetour from './ApercuEchantillon';

export default function Echantillon() {

    const Echantillondata = useSelector(state => state.echantillonsData);
    const ajoutSuccess = Echantillondata.ajoutsuccess;
    const id = useSelector(state => state.userData.id);


    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [Chargement, setChargement] = useState(false);
    const [Listechantillons, setListechantillons] = useState([]);

    const listAchats=useRef([]);

    const navigate = useNavigate();
    const dispatch= useDispatch();

    const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };

      const Ajouter=() => {
        navigate('/home/stock/nouvelechantillon');

        }

        const HideSuccessMsg = () => {
            setTimeout(() => {
                dispatch(ajoutsuccess(false));
                setRemoveSuccess(false);
            }, 5000);
        } 

        const GetEchantillions=async () => {
            setChargement(true);
            try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}echantillions/${id}`);
            setListechantillons(response.data);
            }
         catch (error) {
            console.error('Error:', error);    
        }
        setChargement(false);
        }

        const ShowEchantillion=async (idechantillion) => {
            try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}list/echantillions/${id}/${idechantillion}`);
            listAchats.current = response.data.produits
            dispatch(showapercu(true))
            }
            catch (error) {
                console.error('Error:', error);    
            }
          }

          const suppression = async (idechantillon)=> {

            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(`${apiUrl}delete/echantillion/${id}/${idechantillon}`);
                if(response.data.status==="ok")
                    {
                        setListechantillons(Listechantillons.filter(element => element._id !== idechantillon))
                        setRemoveSuccess(true);
                        HideSuccessMsg();
                    }
            } catch (error) {
                console.log(error)
            }
          }

          const supprimer = (idechantillon) => {

            let result = window.confirm(`Supprimer cet échantillon ?`);
            if (result === true) {
                suppression(idechantillon);
            }
          }

        function ShowList() {
            HideSuccessMsg();
            if(Listechantillons.length > 0){
            return Listechantillons.map(echantillon => {
                return(
                    <tr key={echantillon._id}>
                    <td>{echantillon.remarque}</td>
                    <td>{echantillon.date!=="" ? formatDateToFrench(echantillon.date) : ''}</td>
                    <td>
                    <button type="button" className="btn btn-primary me-4" onClick={() =>ShowEchantillion(echantillon._id)}>Aperçu</button>
                    <button type="button" class="btn btn-danger" onClick={() => supprimer(echantillon._id)}>Supprimer</button>
                    </td>
                </tr>                
                )
            })
        }
        else{
            return <tr>
                <td colSpan={5} align='center'>Pas de données</td>
            </tr>;
        }
        }

        useEffect(() =>{
            HideSuccessMsg();
            GetEchantillions();
          },[])
    return (
        <>
        <div className="container-sm align-items-center">
    
        <section className="mt-4">
        <p className="h2 text-center">Echantillons</p>
    
        {Chargement? 
                    <div d-flex className="d-flex justify-content-center">
                    <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  </div>
                :
                <div style={{ height: '3rem' }}></div>
                }
    
    {ajoutSuccess ?
                <div class="alert alert-success  mb-4 text-center" role="alert">
                <strong>Echantillon ajouté avec succès</strong>
                </div>                 
                    :
                    ''                    
                }
    
    {removeSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Echantillon suprimé avec succès</strong>
            </div>                 
                :
                ''                    
            }
                    <div className="d-flex justify-content-end mb-3">
            
            <button className="btn btn-primary mr-2" onClick={Ajouter}>
              <i className="fas fa-user-plus"></i> Ajouter un échantillon
            </button>
          </div>
          <div class="table-responsive">
        <table className="table  align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Remarque</th>
                    <th scope="col">Date</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ShowList/>
            </tbody>
        </table>
        </div>
        <EchantillonRetour Achats={listAchats} />
        </section>
        </div>
        </>
      )
}
