
import {useState,useEffect, useRef} from 'react';
import Catlist from '../produits/Catlist';
import { useSelector,useDispatch } from 'react-redux';
import { getList } from "../produits/ProduitsSlice";

import axios from 'axios';

export default function ProduitsStock() {
    const [Chargement, setChargement] = useState(false);
    const [rechercheCat,setRchercheCat] = useState('tout');
    const [rechercheInput,setRchercheInput] = useState('');

    const id = useSelector(state => state.userData.id);
    const listProduits=useSelector(state => state.produitsData.list);

    const dispatch= useDispatch();

    const rechercheField=useRef('');
    const catField=useRef('');
    const productLivred=useRef([]);

    const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        const idcat=catField.current.value
        if(recherche!==rechercheInput){
        setRchercheInput(recherche)
         }
         if(idcat!==rechercheCat){
        setRchercheCat(idcat)
    }

    }

  function LaCategorie({cat}) {
    return cat.map(Categorie => {
        return(
            Categorie.designation
        );
    });
};
    function ShowList() {
      if(listProduits.length > 0 ){

        const flatData = productLivred.current.flat();

        const mergedData = flatData.reduce((acc, item) => {
            const existingItem = acc.find(i => i._id === item._id);
            if (existingItem) {
                existingItem.stock = parseInt(existingItem.stock) + parseInt(item.stock);
            } else {
                acc.push({ ...item, stock: parseInt(item.stock) });
            }
            return acc;
        }, []);

        const tableauFusionne = listProduits.map(product => {
            const element = mergedData.find(element => element._id === product._id);
            if (element) {
                return {
                    ...product,
                    stock_financier: product.stock - Number(element.stock)
                };
            } else {
                return {
                    ...product,
                    stock_financier: product.stock
                };
            }
        });
          let listFiltre=tableauFusionne
          if(rechercheInput.length > 2) {
              listFiltre = listFiltre.filter(produit => produit.designation.includes(rechercheInput))
          }
          if (rechercheCat !=="tout"){
              listFiltre = listFiltre.filter(produit => 
                  produit.categorie.some(categorie => 
                    categorie.id===rechercheCat) 
                  )
              ;               
          }
      return listFiltre.map(Produit => {
          return(
              <tr key={Produit._id}>
              <td>{Produit.designation}</td>
              <td><LaCategorie cat={Produit.categorie}/></td>
              <td>{Produit.stock}</td>
              <td>{Produit.stock_financier}</td>
          </tr>                
          )
      })
  }
  else{
      return <tr>
          <td colSpan={4} align='center'>Pas de données</td>
      </tr>;
  }
  }
  
    const getProduits=async () => {
      setChargement(true);
      try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}produits/${id}`);
      const response2 = await axios.post(`${apiUrl}produitsnotlivred/${id}`);
      productLivred.current = response2.data
      dispatch(getList(response.data));
      }
   catch (error) {
      console.error('Error:', error);    
  }
  setChargement(false);
  }

    useEffect(() =>{
      getProduits();
      },[]);
  return (
    <>
    <div className="container-sm align-items-center">
    <section className="mt-4">
    <p className="h2 text-center">Produits</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

<div className="d-flex justify-content-start mb-3 w-50" >
        
        <input type="text" className="form-control w-50 " id="designation"  placeholder="Recherche produit" ref={rechercheField} onKeyUp={Recherche} ></input>
      
        <select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={catField} value={rechercheCat} onChange={Recherche}>
                  <option value="tout">Catégories</option>
                  <Catlist/>
      </select>
          </div>
          <div class="table-responsive">
          <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Désignation</th>
                <th scope="col">Catégorie</th>
                <th scope="col">Stock réel</th>
                <th scope="col">Stock financier</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
        </section>
    </div>
    </>
  )
}
