import React from 'react'

export default function Benefice() {
  return (
    <div className="container-lg align-items-center">
        <section className="mt-4">
        <div class="alert alert-info  mb-4 text-center" role="alert">
        <strong>Pas assez de données</strong>
        </div>            
        </section>
        </div>
  )
}
