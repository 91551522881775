import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current} from "./ChequesSlice";
import axios from 'axios';
import Select from 'react-select';

export default function ModalModifier(CurrentIdClient) {
    const getidclient=CurrentIdClient.CurrentIdClient.currentOption

    const apiUrl = process.env.REACT_APP_API_URL;
    const ChequeData = useSelector(state => state.chequesData);
    const currentelement = ChequeData.current;
    const modalShow = ChequeData.modalModifShow;
    const id = useSelector(state => state.userData.id);
    const listClients = ChequeData.listClients;
    const [loading,setLoading] =useState(false)
    const [currentOption,setCurrentOption] = useState('')

    const [etat,setEtat] =useState('')

    const numeroField=useRef('')
    const dateField=useRef('')
    const montantField=useRef('')
    const remarqueField = useRef('')
    const clientField=useRef('')
    const etatField = useRef('')

    const formatDateToFrench = (dateString) => {
        try {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${year}-${month}-${day}`;
        }
        catch (error) {
        }  
      };

      const formatDateToIso = (dateString) => {
        const formattedDate = new Date(`${dateString}T00:00:00Z`);
        return formattedDate.toISOString();
        };

        const dispatch= useDispatch();
        const Close = () => {
            dispatch(modalModifShow(false));
            setEtat('');
            
        };

        const handleChange = selectedOption => {
            setCurrentOption(selectedOption);
          };

          const ChampUpdate = () =>{
            const _id = currentelement._id;
            const numero=numeroField.current.value
            const date = dateField.current.value;
            const etat=etatField.current.value;
            const montant=montantField.current.value;
            const remarque = remarqueField.current.value;
            
            const valeur={_id,numero,etat,montant,remarque,date}
            dispatch(current(valeur));
          }
          
    const Ajouter = async ()=> {
        const numero=numeroField.current.value.trim()
        const date=formatDateToIso(dateField.current.value)
        let montant=montantField.current.value
        const etat=etatField.current.value;
        montant=montant.replace(",", ".");
        const remarque=remarqueField.current.value
        const dataclient=clientField.current.getValue()
        const { value, label } = dataclient[0];
        const client={
            "id" : value,
            "designation" :label,
        }
        setEtat('')
        setLoading(true)
        try {
            const idcheque = currentelement._id;
            const response = await axios.post(`${apiUrl}edit/cheque/${id}/${idcheque}`, {
                client,
                numero,
                montant,
                remarque,
                etat,
                date,
            });
            if(response.data.status==="ok")
                {
                    const data={idcheque,client,numero,montant,remarque,etat,date};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                }
                else{
                    setEtat('erreur');
                }
            } catch (error) {
                setEtat('erreur');
            }
            setLoading(false)
    }
    useEffect(() =>{
        setEtat('');
        setCurrentOption(getidclient);
      },[CurrentIdClient]);
    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter un chèque</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }

                    <label className="form-label" for="categorie">Client</label>
                    <Select options={listClients} placeholder="Rechercher un client" ref={clientField} value={currentOption} onChange={handleChange} />

                    
                    <label className="form-label" for="designation">Numéro de chèque</label>
                    <input type="text" className="form-control" id="numero"  ref={numeroField} value={currentelement.numero} onChange={ChampUpdate}></input>
                    
                    <label className="form-label" for="montant">Montant</label>
                    <input type="number" className="form-control" id="contact" ref={montantField} value={currentelement.montant} onChange={ChampUpdate}></input>

                    <label className="form-label" for="etat">Etat</label>
                    <select className="form-select form-select-sm" id="etat" ref={etatField} value={currentelement.etat} onChange={ChampUpdate}>

                    <option value="Enregistré">Enregistré</option>
                    <option value="En attente d'encaissement">En attente d'encaissement</option>
                    <option value="Encaissé">Encaissé</option>
                    </select>


                    <label className="form-label" for="date">Date d'encaissement</label>
                    <input type="date" className="form-control" id="date" ref={dateField} value={formatDateToFrench(currentelement?.date)} onChange={ChampUpdate}></input>

                    <label className="form-label" for="remarque">Remarque</label>
                    <input type="text" className="form-control" id="remarque" ref={remarqueField} value={currentelement.remarque} onChange={ChampUpdate}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
