import axios from 'axios';
import { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function Listfournisseurs() {

    const [listfournisseur, setListfournisseur] = useState([]);
    const id = useSelector(state => state.userData.id);

    function Fournisseurs(){

        return listfournisseur.map((fournisseur,index) => {
            return(
                <option key={index} value={fournisseur._id}>
                    {fournisseur.designation}
                    </option>
            );
        });
    }

    const getFournisseurs=async () => {
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}fournisseurs/${id}`);
        setListfournisseur(response.data);
        }
     catch (error) {
        console.error('Error:', error);
    }
    }

useEffect(() =>{
    getFournisseurs();
    },[]);

  return (
    <Fournisseurs/>
  )
}
