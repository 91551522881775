import {useState,useEffect,useRef} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove } from "./ChargesSlice";

import axios from 'axios';
import ListTypeCharges from './ListTypeCharges';
import ModalAjouter from './ModalAjouter';
import ModalModifier from './ModalModifier';

export default function Charges() {

    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [Chargement, setChargement] = useState(false);
    const userData = useSelector(state => state.userData);
    const id = userData.id;
    const typeUser = userData.type;    
    const ChargesData = useSelector(state => state.chargesData);
    const ListCharges=ChargesData.list;  
    const ajoutSuccess=ChargesData.ajoutOk;  
    const modifSuccess = ChargesData.modifOk;

    const [rechercheCat,setRechercheCat] = useState('tout');
    const dispatch= useDispatch();

    const catField=useRef('');
    const DatedebutField= useRef('')
    const DateFinField = useRef('')

    const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      };

      const formatDateToEnglish = (dateString) => {
        const [date] = dateString.split('T');
        return `${date}`;
      };

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selectedDate2, setSelectedDate2] = useState(todayDate);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
    
      const handleDateChange2 = (event) => {
        setSelectedDate2(event.target.value);
      };  

    const Ajouter = () => dispatch(showmodal(true));

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
        }, 5000);
    }

    const Recherche=() =>{
        const idcat=catField.current.value
         if(idcat!==rechercheCat){
            setRechercheCat(idcat)
    }
    }

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = ListCharges.find(charge => charge._id === id);
        dispatch(current(valeur));
    };

    const suppression = async (idcharge,frequence,idfrequence)=> {
        setChargement(true)
        try {
            if(frequence==="Ponctuelle")
            {
                idfrequence = "1"
            }
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/charge/${id}/${idcharge}/${frequence}/${idfrequence}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idcharge));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
        setChargement(false)
    }

    const supprimer = (idcharge,frequence,designation,idfrequence) => {

        let result = window.confirm(`Supprimer la charge ${designation} ?`);
        if (result === true) {
            suppression(idcharge,frequence,idfrequence);
        }
    }

    // const totalMontant = ListCharges.reduce((acc, charge) => acc + charge.montant, 0);
    // const totalMontantArrondi = totalMontant.toFixed(2);
    // console.log(totalMontantArrondi)

    function ShowList() {
        HideSuccessMsg();
        if(ListCharges.length > 0){
            let listFiltre=ListCharges
            listFiltre = listFiltre.filter(charge => {
                const chargeDate = formatDateToEnglish(charge.date);
                const dateDebut = DatedebutField.current.value
                const dateFin = DateFinField.current.value
                return chargeDate >= dateDebut && chargeDate <= dateFin;
            });


            if (rechercheCat !=="tout"){
                listFiltre = listFiltre.filter(charge => charge.categorie===rechercheCat )
                ;}
        
        let total = 0
        const datalist=listFiltre.map(Charge => {
            total = parseFloat(total) + parseFloat(Charge.montant)
            return(
                <tr key={Charge._id}>
                <td>{Charge.categorie}</td>
                <td>{Charge.frequence}</td>
                <td>{Charge.montant}</td>
                <td>{Charge.designation}</td>
                <td>{Charge.date!=="" ? formatDateToFrench(Charge.date) : ''}</td>
                {typeUser==="admin" ?
                <td>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(Charge._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(Charge._id,Charge.frequence,Charge.designation,Charge.idcharge)}>Supprimer</button>
                </td>
                :
                ''
                }
            </tr>                
            )
        })
        const champTotal =(
            <tr>
                <th colSpan={6} style={{ textAlign: 'center' }}> Total : {total} Dhs</th>
            </tr>
        )
        return (
            <>
            {datalist}
            {champTotal}
            </>
        )
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    }

    const getCharges=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}charges/${id}`);
        dispatch(getList(response.data));
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }

    useEffect(() =>{
        getCharges();
        dispatch(showmodal(false));
        setSelectedDate(todayDate);
        setSelectedDate2(todayDate);
        },[]);

    return (
        <>
        <div className="container-lg align-items-center">
    <section className="mt-4">
        <p className="h2 text-center">Charges</p>
        {Chargement? 
                    <div d-flex className="d-flex justify-content-center">
                    <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  </div>
                :
                <div style={{ height: '3rem' }}></div>
                }
        <div className="d-flex justify-content-end mb-3">
        <button className="btn btn-primary mr-2" onClick={Ajouter}>
          <i className="fas fa-user-plus"></i> Ajouter une charge
        </button>
      </div>
    
      {ajoutSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Charge ajoutée avec succès</strong>
            </div>                 
                :
                ''                    
            }
    
    {modifSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Charge modifiée avec succès</strong>
            </div>                 
                :
                ''                    
            }
    
    {removeSuccess ?
            <div class="alert alert-success  mb-4 text-center" role="alert">
            <strong>Charge supprimée avec succès</strong>
            </div>                 
                :
                ''                    
            }

<div className="d-flex justify-content-start mb-3 w-50" >
        
      
        <select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={catField} onChange={Recherche}>
                  <option value="tout">Catégories</option>
                  <ListTypeCharges/>
      </select>

        <label className="form-label ms-5" >Du</label>
        <input type="date" className="form-control w-25 ms-3" id="date1" value={selectedDate} onChange={handleDateChange}  ref={DatedebutField} ></input>

        <label className="form-label ms-5" >Au</label>
        <input type="date" className="form-control w-25 ms-3" id="date2" value={selectedDate2} onChange={handleDateChange2}  ref={DateFinField} ></input>

          </div>
          <div class="table-responsive">
      <table className="table  align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Catégorie</th>
                    <th scope="col">Fréquence</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Désignation</th>
                    <th scope="col">Date</th>
                    {typeUser==="admin" ?
                <th scope="col">Action</th>
                :
                ''  
                }                
            </tr>
            </thead>
            <tbody>
                <ShowList/>
            </tbody>
        </table>
        </div>
    </section>
        </div>
        <ModalAjouter />
        <ModalModifier/>
    </>
        
      )
}
