import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Catlist from '../produits/Catlist';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { modifsuccess,showapercu } from "./AchatsSlice";
import Listfournisseurs from './Listfournisseurs';
import Apercu from './Apercu';

export default function EditAchats() {
    const id = useSelector(state => state.userData.id);
    const currentachat = useSelector(state => state.achatsData.currentAchat);
    const [Chargement, setChargement] = useState(false);
    const [listProduits, setListProduits] = useState([]);
    const [rechercheInput,setRchercheInput] = useState('');
    const [rechercheCat,setRchercheCat] = useState('tout');
    const [rechercheFournisseur,setrechercheFournisseur] = useState('');

    const [etat,setEtat] =useState('')

    const dispatch= useDispatch();

    const catField=useRef('');
    const rechercheField=useRef('');
    const fournisseurField=useRef('');
    const factureField=useRef('');
    const montantField=useRef('');
    const etatField=useRef('');
    const dateArrivageField=useRef('');
    const dateReglementField=useRef('');    
    let listAchats=useRef([]);

    const navigate = useNavigate();

const removeZeroStock = (list) => {
    return list.current.filter(item => item.stock !== "0");
  };

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${year}-${month}-${day}`;
  };
  const formatDateToIso = (dateString) => {
    const formattedDate = new Date(`${dateString}T00:00:00Z`);
    formattedDate.setHours(23, 59, 59, 999);
    return formattedDate.toISOString();
    };
    
    const StockUpdate = (event) => {
        const qte = event.target.value;
        const id = event.target.id;
        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                product.stock = qte
                const _id=product._id
                const designation=product.designation
                const stock=product.stock
                const valeurs={_id,designation,stock}
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock
                    listAchats.current[existingProductIndex].stock = stock;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                return { listAchats };
            }
            return product;
        });
        
    };

    const ShowApercu =() =>{
        dispatch(showapercu(true))
   }

    function ShowList() {
        if(listProduits.length > 0){
            let listFiltre=listProduits.filter(produit => produit.fournisseur["0"].id===rechercheFournisseur)
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(produit => produit.designation.includes(rechercheInput))
            }
            if (rechercheCat !=="tout"){
                listFiltre = listFiltre.filter(produit => 
                    produit.categorie.some(categorie => 
                      categorie.id===rechercheCat) 
                    )
                ;               
            }
        return listFiltre.map(Produit => {
            const currentQte = listAchats.current.find(item => item._id === Produit._id);
            let stock="0";
            if(currentQte){
                stock=currentQte.stock;
            }

            return(
                <tr key={Produit._id}>
                <td>{Produit.designation}</td>
                <td><input type="number" className="form-control-sm" id={Produit._id} defaultValue={stock}  onChange={StockUpdate} placeholder="Quantité"></input></td>
            </tr>                
            )
            
        });
        
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    
    } 

    const getListAchat=async (result) => {
        setChargement(true);
        try {

        const apiUrl = process.env.REACT_APP_API_URL;
        const idachat = currentachat.map(achat => achat._id);
        const response = await axios.post(`${apiUrl}list/achat/${id}/${idachat}`);
           response.data.produits.map(product => {
            const _id=product._id
            const designation=product.designation
            const stock=product.stock
            
            const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
            if (existingProductIndex === -1) {
            const valeurs = {_id,designation,stock};
            listAchats.current.push({...valeurs});
            }

        });
        setListProduits(result);
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }

    const getProduits=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}produits/${id}`);
        const result = response.data;
        getListAchat(result);
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }

    const InitFournisseur=() =>{
        setrechercheFournisseur(currentachat.map(achat => achat.fournisseur["0"].id))
        fournisseurField.current.value=currentachat.map(achat => achat.fournisseur["0"].id)
        Recherche2()
    }

    const Recherche2 =() => {
        setrechercheFournisseur(fournisseurField.current.value)
       }

    const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        const idcat=catField.current.value
        if(recherche!==rechercheInput){
        setRchercheInput(recherche)
         }
         if(idcat!==rechercheCat){
        setRchercheCat(idcat)
    }

    }
    const Modifier = async () => {
        const idfournisseur=fournisseurField.current.value
        let fournisseur=fournisseurField.current.options[fournisseurField.current.selectedIndex].text
        fournisseur=[{
            "id" : idfournisseur,
            "designation" : fournisseur,
        }];
        let facture = factureField.current.value
        if(facture===""){
            facture=0
        }
        let montant = montantField.current.value
        if(montant===""){
            montant=0
        }
        const etat = etatField.current.value
        const dateArrivage = formatDateToIso(dateArrivageField.current.value)
        const dateReglement = formatDateToIso(dateReglementField.current.value)
        const produits = removeZeroStock(listAchats)
        setEtat('');
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const idachat = currentachat.map(achat => achat._id);
        const response = await axios.post(`${apiUrl}edit/achat/${id}/${idachat}`,{
                fournisseur,
                facture,
                montant,
                etat,
                dateArrivage,
                dateReglement,
                produits,
            });
            dispatch(modifsuccess(true));
            navigate('/home/achats', { replace: true });
        }
     catch (error) {
        console.error('Error:', error);
        setEtat('erreur');    
    }
    setChargement(false);
    }    
    useEffect(() =>{
        getProduits();
      },[]);
      useEffect(() =>{
        InitFournisseur();
      },[listProduits]);
  return (
        <>
        <div className="container-lg align-items-center">
    <section className="mt-4">
        <p className="h2 text-center">Modifier Achat</p>

        {Chargement? 
                <div d-flex className="d-flex justify-content-center mb-2">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

                        {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                                </div>                 
                                :
                                ''                    
                        }   
    <div class="table-responsive">
      <table className="table align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Fournisseur</th>
                    <th scope="col">N° facture</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Etat</th>
                    <th scope="col">Date d'arrivage</th>
                    <th scope="col">Date réglement</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                 <td>
                 <select className="form-select" id="fournisseur" ref={fournisseurField} value={rechercheFournisseur} onChange={Recherche2}>
            <Listfournisseurs/>
            </select>    
                </td>
                <td><input type="number" className="form-control" id="facture" placeholder="n° facture" ref={factureField} defaultValue={currentachat.map(achat => achat.facture)}></input></td>   
                <td><input type="number" className="form-control" id="montant" placeholder="Montant" ref={montantField} defaultValue={currentachat.map(achat => achat.montant)}></input></td>   
                <td>
                <select className="form-select" id="etat" ref={etatField} defaultValue={currentachat.map(achat => achat.etat)}>
                    <option value="Réglée">Réglée</option>
                    <option value="Non Réglée">Non Réglée</option>
                    </select>
                </td>
                <td><input type="date" className="form-control"  ref={dateArrivageField} defaultValue={formatDateToFrench(currentachat['0'].dateArrivage)}></input></td>   
                <td><input type="date" className="form-control" ref={dateReglementField} defaultValue={formatDateToFrench(currentachat['0'].dateReglement)}></input></td>   

                </tr>
            </tbody>
        </table>
        </div>
        <p className="h2 text-center">Produits</p>
        <div className="d-flex justify-content-start mb-3 w-50" >
        <input type="text" className="form-control w-50 " id="designation"  placeholder="Recherche produit" ref={rechercheField} onKeyUp={Recherche} ></input>

<select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={catField} value={rechercheCat} onChange={Recherche}>
          <option value="tout">Catégories</option>
          <Catlist/>
</select>
</div>
<div class="table-responsive">
        <table className="table align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Désignation</th>
                    <th scope="col">Quantité</th>
                </tr>
            </thead>
            <tbody>
            <ShowList/>
            </tbody>
            </table>
            </div>

            <div className="d-flex justify-content-end mb-3">
            <button type="button" className="btn btn-primary me-2" onClick={ShowApercu}>Aperçu</button>        
            <button type="button" className="btn btn-success" onClick={Modifier}>Enregistrer</button>
      </div>
    </section>
    <Apercu Achats={listAchats} />
        </div>

    </>
        
      )
}
