import { NavLink,Outlet } from 'react-router-dom';
export default function Statistiques() {
    return (
        <>
        <div className="container-lg align-items-center">
        <section className="mt-4">
        <p className="h3 text-center">Statistiques</p>
    
        <ul class="nav nav-underline mt-4">
      <li class="nav-item">
      <NavLink className="nav-link" to="dashboard">Vue d'ensemble</NavLink>
      </li>
      <li class="nav-item">
      <NavLink className="nav-link" to="benefice">Bénéfices et Charges</NavLink>
      </li>
      <li class="nav-item">
      <NavLink className="nav-link" to="benefice">Répartition par produit</NavLink>
      </li>
      <li class="nav-item">
      <NavLink className="nav-link" to="benefice">Répartition par catégorie</NavLink>
      </li>
      <li class="nav-item">
      <NavLink className="nav-link" to="benefice">Répartition par client</NavLink>
      </li>
    </ul>
    </section>
    </div>
    <section className="mt-4"></section>
    <Outlet/>
        </>
    
        
      )
}
