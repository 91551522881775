
import {useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { showapercu } from "./FactureSlice";

export default function ModalApercu({Achats,Total,Avoir}) {
    const FacturesData = useSelector(state => state.facturesData);
    const modalShow = FacturesData.apercuShow;

    const [showAvoir, setshowAvoir] = useState(false);
    const [TotalValue, setTotalValue] = useState(0);

    const dispatch= useDispatch();

    function ShowList() {
        let remiseAvoir = 0
        
        if(Avoir.current.montant){
            setshowAvoir(true)
            remiseAvoir = parseFloat(Avoir.current.montant)
        }
        else{
            setshowAvoir(false)
        }
        setTotalValue(parseFloat(Total)-remiseAvoir)
        const listFiltre = Achats.current.filter(achat => achat.stock>0)
        return(
            listFiltre.map(achat =>{
                return(
                    <>
                    <tr key={achat._id}>
                        <td>{achat.designation}</td>
                        <td>{achat.stock}</td>
                        <td>{achat.prix_vente}</td>
                    </tr>


                    </>
                )

            })
        )
    }

    const Close = () => {
        dispatch(showapercu(false));   
    };    
    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Aperçu de la facture</h5>
                    </div>
                    <div className="modal-body">
                    <table className="table  align-middle table-dark table-striped mb-3">
            <thead>
                <tr>
                    <th scope="col">Produit</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Prix HT</th> 
                </tr>
            </thead>
            <tbody>
                <ShowList/>
                {showAvoir ? 
                     <tr>
                     <td colSpan={3} align='center'> Avoir N° {Avoir.current.numero} / Montant : {Avoir.current.montant?.toFixed(2)} Dhs</td>
                     </tr>      
                     :
                     ''                 
                    }
                <tr>
                <td colSpan={3} align='center'> Total : {TotalValue.toFixed(2)} Dhs</td>
                </tr>
            </tbody>
        </table>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={Close}>Fermer</button>

                    </div>
                </div>
            </div>
        </div>
    );

    }


