import {useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function Dashboard() {
  const id = useSelector(state => state.userData.id);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [ChiffreAffaire, setChiffreAffaire] = useState("--");
  const [Charges, setCharges] = useState("--");
  const [Benifice, setBenifice] = useState("--");
  const [ValeurStock, setValeurStock] = useState("--");
  const [ValeurCheques, setValeurCheques] = useState("--");

  const styles = {
    card: {
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginBottom: '1.5rem',
    },
    cardBody: {
      padding: '1.5rem',
    },
    media: {
      display: 'flex',
      alignItems: 'center',
    },
    image: {
      width : '60px',
      height : '60px',
      marginRight: '1rem',
    },
    textCheque: {
      textAlign: 'right',
      color: '#00B5B8',
    },
    textPromo: {
        textAlign: 'right',
        color: '#FFA808',
      },
      textFacture: {
        textAlign: 'right',
        color: '#FF7588',
      },
      textStock: {
        textAlign: 'right',
        color: '#16D39A',
      },
    h3: {
      marginBottom: '0.5rem',
      fontWeight: 'bold',
      
    },
    span: {
      fontSize: '1rem',
      color: '#6c757d',
      fontWeight: 'bold',
    },
  };
    const currentDate = new Date();

  // Obtenir le début du mois
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Obtenir la fin du mois
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999);

  const GetStats=async () => {
    try {
    
    const response = await axios.post(`${apiUrl}statistiques/ventes/${id}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`);
    setChiffreAffaire(response.data.total)
    const response2 = await axios.post(`${apiUrl}statistiques/charges/${id}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`);
    setCharges(response2.data.total)
    const charge = parseFloat(response2.data.total)

    const response3 = await axios.post(`${apiUrl}statistiques/benifice/${id}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`);
    const data=response3.data.produits.flat()

    const calculateSum = (products) => {
      return products.reduce((sum, product) => {
          return sum + ((product.prix_vente - product.prix_achat) * product.stock);
      }, 0);
  };
  let benef = calculateSum(data);
  benef = benef.toFixed(2)


    const benifice = (benef - charge).toFixed(2)
    setBenifice(benifice)
    }
 catch (error) {
    console.error('Error:', error);    
}
}

const GetStockValue=async () => {
  try {
  

  const response = await axios.post(`${apiUrl}statistiques/stockvalue/${id}`);
  const data = response.data
  
  const response2 = await axios.post(`${apiUrl}produitsnotlivred/${id}`);
  const data2 = response2.data.flat()

  const tableauFusionne = data.map(product => {
    const element = data2.find(element => element._id === product._id);
    if (element) {
      return {
      ...product,
      stock : product.stock - Number(element.stock)
    }
    }
    return product;
});
  
  const calculateSum = (products) => {
    return products.reduce((sum, product) => {
        return sum + (product.prix_achat * product.stock * 1.2);
    }, 0);
};
  let sum = calculateSum(tableauFusionne);
  sum = sum.toFixed(2)
  setValeurStock(sum)

}

catch (error) {
  console.error('Error:', error);    
}
}

const GetCheques=async () => {
  try {
  
  const response = await axios.post(`${apiUrl}statistiques/cheques/${id}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`);

  const calculateSum = (cheques) => {
    return cheques.reduce((sum, cheque) => {
        return sum + (cheque.montant);
    }, 0);
};
  let sum = calculateSum(response.data);
  sum = sum.toFixed(2)
  setValeurCheques(sum)
  }
catch (error) {
  console.error('Error:', error);    
}
}

useEffect(() =>{
  GetStats();
  GetStockValue();
  GetCheques();
},[])

  return (
    <div className="container-lg align-items-center">
      <div className="row mt-4">
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/sales.png`} width={200} height={200} style={styles.image}  alt="promo" />
                  <div style={styles.textCheque}>
                    <h3 style={styles.h3}>{ChiffreAffaire} Dhs</h3>
                    <span style={styles.span}>Chiffre du mois</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/profit.png`} width={200} height={200} style={styles.image}  alt="cheque" />
                  <div style={styles.textStock}>
                    <h3 style={styles.h3}>{Benifice} Dhs</h3>
                    <span style={styles.span}>Bénefice du mois</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/spending.png`} width={200} height={200} style={styles.image}  alt="promo" />
                <div style={styles.textPromo}>
                    <h3 style={styles.h3}>{Charges} Dhs</h3>
                    <span style={styles.span}>Charges du mois</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/stock2.png`} width={200} height={200} style={styles.image}  alt="promo" />
                  <div style={styles.textStock}>
                    <h3 style={styles.h3}>{ValeurStock} Dhs</h3>
                    <span style={styles.span}>Valeur stock</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" style={styles.card}>
            <div className="card-content">
              <div style={styles.cardBody}>
                <div style={styles.media}>
                <img src={`${process.env.PUBLIC_URL}/check.png`} width={200} height={200} style={styles.image}  alt="cheque" />
                  <div style={styles.textFacture}>
                    <h3 style={styles.h3}>{ValeurCheques} Dhs</h3>
                    <span style={styles.span}>Valeur chèques</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
