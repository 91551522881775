import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from "./AvoirsSlice";
import axios from 'axios';
import Select from 'react-select';

export default function ModalModifer(CurrentIdClient) {
    const getidclient=CurrentIdClient.CurrentIdClient.currentOption

    const apiUrl = process.env.REACT_APP_API_URL;
    const AvoirData = useSelector(state => state.avoirsData);
    const currentelement = AvoirData.current;
    const modalShow = AvoirData.modalModifShow;
    const id = useSelector(state => state.userData.id);
    const listClients = AvoirData.listClients;

    const [loading,setLoading] =useState(false)
    const [currentOption,setCurrentOption] = useState('')

    const [etat,setEtat] =useState('')

    const clientField=useRef('')
    const etatField=useRef('')
    const montantField=useRef('')
    const remarqueField=useRef('')
    const dateField=useRef('')

    const formatDateToFrench = (dateString) => {
        try {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${year}-${month}-${day}`;
        }
        catch (error) {
        }  
      };
      
    const formatDateToIso = (dateString) => {
        const formattedDate = new Date(`${dateString}T00:00:00Z`);
        formattedDate.setHours(23, 59, 59, 999);
        return formattedDate.toISOString();
        };

    const Ajouter = async ()=> {
        const dataclient=clientField.current.getValue()
        const { value, label } = dataclient[0];

        const client=[{
            "id" : value,
            "designation" :label,
        }]

        let montant=montantField.current.value
        montant=montant.replace(",", ".");

        const remarque = remarqueField.current.value.trim()
        const date = formatDateToIso(dateField.current.value)

        const etat=etatField.current.value;
        setEtat('')
        setLoading(true)
        try {
            const idavoir = currentelement._id;
            const response = await axios.post(`${apiUrl}edit/avoir/${id}/${idavoir}`, {
                client,
                montant,
                remarque,
                etat,
                date,
            });
            if(response.data.status==="ok")
                {
                    const data={idavoir,client,montant,remarque,etat,date};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                }
            else{
                setEtat('erreur');
            }
        } catch (error) {
            setEtat('erreur');
        }
        setLoading(false)
    }

    const dispatch= useDispatch();
    const Close = () => {
        dispatch(modalModifShow(false));
        setEtat('');
        
    };

    const handleChange = selectedOption => {
        setCurrentOption(selectedOption);
      };
    const ChampUpdate = () =>{
        const _id = currentelement._id;
        const etat=etatField.current.value;
        const montant=montantField.current.value;
        const remarque = remarqueField.current.value;
        const date = dateField.current.value;
        const valeur={_id,etat,montant,remarque,date}
        dispatch(current(valeur));
        
    }
    useEffect(() =>{
        setEtat('');
        setCurrentOption(getidclient);
      },[CurrentIdClient]);

    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Modifier un avoir</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }                    
                    <label className="form-label" for="categorie">Client</label>
                    <Select options={listClients} placeholder="Rechercher un client" ref={clientField} value={currentOption} onChange={handleChange}/>
                    
                    <label className="form-label" for="etat">Etat</label>
                    <select className="form-select form-select-sm" id="etat" ref={etatField} value={currentelement.etat} onChange={ChampUpdate}>

                    <option value="Enregistré">Enregistré</option>
                    <option value="Remboursé">Remboursé</option>
                    {currentelement.etat?.startsWith("Appliqué") ?
                    <option value={currentelement.etat}>{currentelement.etat}</option>
                    :  ''  
                        }
                    </select>
                    <label className="form-label" for="montant">Montant</label>
                    <input type="number" className="form-control" lang="en" id="montant" value={currentelement.montant} ref={montantField} onChange={ChampUpdate}></input>

                    <label className="form-label" for="remarque">Remarque</label>
                    <input type="text" className="form-control" lang="en" id="remarque" value={currentelement.remarque} ref={remarqueField} onChange={ChampUpdate}></input>

                    <label className="form-label" for="date">Date d'avoir</label>
                    <input type="date" className="form-control" id="date" value={formatDateToFrench(currentelement.date)} ref={dateField} onChange={ChampUpdate}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    }


