import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    apercuShow : false,
    ajoutsuccess : false,
    listClients : [],

}

const Retours = createSlice({
    name : 'RetoursData',
    initialState,
    reducers :{
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        }, 
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },  
        listclient:(state,action) =>{
            state.listClients=action.payload
        },
    }
})
export const {showapercu,ajoutsuccess,listclient} = Retours.actions;
export default Retours.reducer;