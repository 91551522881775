import { NavLink,Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Stock() {
  const userData = useSelector(state => state.userData);
  return (
    <>
    <div className="container-lg align-items-center">
    <section className="mt-4">
    <p className="h3 text-center">Gestion du stock</p>

    <ul class="nav nav-underline">
  <li class="nav-item">
  <NavLink className="nav-link" to="produits">Produits</NavLink>
  </li>
  <li class="nav-item">
  <NavLink className="nav-link" to="retourstock">Retour Stock</NavLink>
  </li>
  {userData?.type==="admin" 
        ?
  <li class="nav-item">
  <NavLink className="nav-link" to="echantillon">Echantillons</NavLink>
  </li>
  : 
  ""
}
</ul>
</section>
</div>
<Outlet/>
    </>

    
  )
}
