import React from 'react'

export default function ListTypeCharges() {
  return (
    <>
    <option value='Frais généraux'>Frais généraux</option>
    <option value='Véhicules'>Véhicules</option>
    <option value='Achats'>Achats</option>
    </>
  )
}
