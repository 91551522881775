import {useState,useEffect} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function Catlist() {
    const [categoriesList,setCategoriesList] =useState([])
    const id = useSelector(state => state.userData.id);

    function CateList(){

        return categoriesList.map((Categorie,index) => {
            return(
                <option key={index} value={Categorie._id}>
                    {Categorie.designation}
                    </option>
            );
        });
    }
    const getCategories=async () => {
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}categories/${id}`);
        setCategoriesList(response.data);
        }
     catch (error) {
        console.error('Error:', error);
    }
    }
    useEffect(() =>{
        getCategories();
        },[]);
  return (
    <CateList/>
  )
}
